import { Component, Input, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Dependente } from 'src/app/modules/core/models/tsv/dependente.model';
import { Trabalhador } from 'src/app/modules/core/models/tsv/trabalhador.model';
import { tipoDependente } from '../../../../assets/data';
import { DependenteService } from 'src/app/modules/core/services/dependente/dependente.service';
import { Router } from '@angular/router';
import {PageRequest} from '../../../modules/core/models/page-sort/page-request.model';
import {Sort} from '../../../modules/core/models/page-sort/sort.model';
import {Page} from '../../../modules/core/models/page-sort/page.model';
import {SortDirection} from '../../../modules/core/models/page-sort/sort-direction.enum';
import { formatDate } from '@angular/common';
import  Swal  from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-trabalhador-dependente',
  templateUrl: './trabalhador-dependente.component.html',
  styleUrls: ['./trabalhador-dependente.component.scss']
})
export class TrabalhadorDependenteComponent implements OnInit {

  public data = {
    tipoDependente
  };
  
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dependenteService: DependenteService,
    private activatedRoute: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  @Input() 
  trabalhador: Trabalhador;

  @Input() 
  formTrabalhador: FormGroup;

  @Input()
  isDisabled: boolean;

  public pageRequest: PageRequest;
  public loading: boolean = false;
  public sort: Sort;
  public dependente: Dependente;
  public dependentes: Dependente[];
  public editing: boolean = false;
  public loadingDependente: boolean = false;
  public pg : string = 'dependente';
  public page: Page<Dependente>;
  public andNew: boolean = false;
  public id_ : number;

  ngOnInit(): void {
    this.createForm();
  }

  setDependente(): void {
    this.dependente = new Dependente();
  }

  createForm() : void{

    this.limparEPesquisar();

    if (!this.formTrabalhador?.get('dependentes')) {

     this.newForm();

    }

  }


  newForm() : void{
    
     (this.formTrabalhador as FormGroup)
      .addControl("dependentes", this.formBuilder.group({
        id : 0 as number,
        tipoDependente: 0,
        nomeDependente: '',
        dataNascDependente:  [this.parseDate('')],
        cpf: 0,
        deducaoIRRF: false,
        salarioFamilia: false
      }));

  }


  limparEPesquisar() {
    this.sort = new Sort('id', SortDirection.ASCENDING );
    this.pageRequest = new PageRequest(0, 10, this.sort );
    this.pesquisar();
  }

  pesquisar() {
 
    this.id_ = parseInt(String(this.activatedRoute.snapshot.paramMap.get('id')));

    let value = this.getDataTrbalhador() ;

    this.loadingDependente = true;

    if (value !== null) {
      this.dependenteService.findAllById( this.pageRequest, this.id_ ).subscribe(
        (response) => {
          this.page = response;
        },
        (error) => {
          this.toastr.error('Não foi possível listar os Dependentes do Trabalhador.');
        }
      ).add(() => {
        this.loadingDependente = false;
      });
    }


  }

  getContent(): Dependente[] {
    if (this.page && this.page.content) {
      return this.page.content;
    } else {
      return [];
    }
  }

  newDependente(): void {
    this.formTrabalhador.get('dependentes')?.reset();
    this.editing = true;
    this.andNew  = true;
  }

  addDependente(): void {

    let dependentesForm = this.formTrabalhador.get('dependentes');
    this.editing = false;
    let values = this.getData();
    this.dependente = new Dependente();
    
    this.loading = true;
    this.id_ = parseInt(String(this.activatedRoute.snapshot.paramMap.get('id')));
    
    if ( this.id_ > 0 ){
      if(this.andNew){

        this.dependenteService.save( values as Dependente, this.pg )
        .subscribe({
          next: (response) => {
            console.log('AO GRAVAR', response);
          },
          error: (error) => {
            this.toastr.error(error.error.message);
            this.loading = false;
          },
          complete: () => {
            Swal.fire('Trabalhador Sem Vinculo', 'Dependente Inserido com sucesso!', 'success');
            this.cancelEdit();
          }
      
        })
      }else{

        this.dependente.id         = parseInt(  String(this.formTrabalhador.controls.dependentes.value.id ) ) as number;

        this.dependenteService.update( this.dependente.id as number , values as Dependente, this.pg )
          .subscribe({
            next: (response) => {
              console.log('AO GRAVAR', response);
            },
            error: (error) => {
              this.toastr.error(error.error.message);
              this.loading = false;
            },
            complete: () => {
              this.loading = false;
              Swal.fire('Dependente Trabalhador Sem Vinculo', 'Alterado com sucesso!', 'success');
              this.cancelEdit();
            }
          })
      }   
     
    }else{

      Swal.fire('Trabalhador Sem Vinculo', 'Salve primeiro os dados principais', 'warning');
      this.loading = false;

    }

    this.cancelEdit();
    this.loading = false;



  }
   
  getData(): Object {
    return {
      id: parseInt( String(this.formTrabalhador.controls.dependentes.value.id )),
      tipoDependente: parseInt( String(this.formTrabalhador.controls.dependentes.value.tipoDependente)),
      nomeDependente: String(this.formTrabalhador.controls.dependentes.value.nomeDependente),
      dataNascDependente: this.transformDate(this.formTrabalhador.controls.dependentes.value.dataNascDependente),
      cpf            : parseInt(  String( this.formTrabalhador.controls.dependentes.value.cpf.replace(/[^a-z0-9]/gi,'') ) ),
      deducaoIRRF    : this.parseBoolForSimOrNao( this.formTrabalhador.controls.dependentes.value.deducaoIRRF ),
      salarioFamilia : this.parseBoolForSimOrNao( this.formTrabalhador.controls.dependentes.value.salarioFamilia ),
      trabalhador    : this.getDataTrbalhador() as Trabalhador
    }
  }

  editEndereco( dependente: Dependente) {

    let dependentes = this.formTrabalhador.get('dependentes');
  
    dependentes?.get('id')?.setValue(dependente.id );
    dependentes?.get('tipoDependente')?.setValue(dependente.tipoDependente );
    dependentes?.get('nomeDependente')?.setValue(dependente.nomeDependente );
    dependentes?.get('dataNascDependente')?.setValue( this.transformDate( dependente.dataNascDependente )  );
    dependentes?.get('cpf')?.setValue( this.setCpf( String(dependente.cpf)) );
    dependentes?.get('deducaoIRRF')?.setValue( this.parseSimOrNaoForBool(dependente.deducaoIRRF) );
    dependentes?.get('salarioFamilia')?.setValue( this.parseSimOrNaoForBool(dependente.salarioFamilia) );


    this.editing = true;
    this.andNew  = false;
  }

  cancelEdit() {
    this.editing = false;
    this.formTrabalhador.get('dependentes')?.reset();
    this.limparEPesquisar();
  }

  removeEndereco(index: any) {
    
    Swal.fire({
      title: 'Você tem certeza?',
      text: "Você vai deletar permanetemente!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, Deletar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.dependenteService.delete( index as number, this.pg )
        .subscribe({
          next: (response) => {
            console.log('AO Deletar', response);
          },
          error: (error) => {
            this.toastr.error(error.error.message);
            this.loading = false;
          },
          complete: () => {
            Swal.fire('Trabalhador Sem Vinculo', 'Endereço deletado com sucesso!', 'success');
            this.dependentes.splice(index, 1);
            this.cancelEdit();
          }
      
        })
      }
    })

  }

  getDataTrbalhador(): Object {

    let trabalhador = this.formTrabalhador.get('trabalhador') ;

    return {
      id: parseInt(String(this.activatedRoute.snapshot.paramMap.get('id'))),
      cpf:  parseInt( trabalhador?.get('cpf')?.value.replace(/[^a-z0-9]/gi,'') ),
      nome: String( trabalhador?.get('nome')?.value),
      sexo: trabalhador?.get('sexo')?.value,
      racaCor: trabalhador?.get('racaCor')?.value ,
      estadoCivil: trabalhador?.get('estadoCivil')?.value ,
      tpGrauInstr: parseInt( trabalhador?.get('grauInstrucao')?.value ),
      nomeSocial: String( trabalhador?.get('nomeSocial')?.value ),
      dataNascimento: this.transformDate(trabalhador?.get('dataNascimento')?.value ) ,
      
      resideExterior: this.parseBoolForSimOrNao( trabalhador?.get('resideExterior')?.value ),
      imigrante: this.parseBoolForSimOrNao( trabalhador?.get('imigrante')?.value ),
      possuiDeficiencia: this.parseBoolForSimOrNao( trabalhador?.get('possuiDeficiencia')?.value ),
      possuiDependentes: this.parseBoolForSimOrNao( trabalhador?.get('possuiDependentes')?.value ),
      paisNascimento: parseInt( String(trabalhador?.get('paisNascimento')?.value.id ) ),
      paisNacionalidade: parseInt( String(trabalhador?.get('paisNacionalidade')?.value.id )),
      deficienteFisico: this.parseBoolForSimOrNao( trabalhador?.get('deficienteFisico')?.value ),
      deficienteVisual: this.parseBoolForSimOrNao( trabalhador?.get('deficienteVisual')?.value ),
      deficienteAuditivo: this.parseBoolForSimOrNao( trabalhador?.get('deficienteAuditivo')?.value ),
      deficienteMental: this.parseBoolForSimOrNao( trabalhador?.get('deficienteMental')?.value ),
      deficienteIntelectual: this.parseBoolForSimOrNao( trabalhador?.get('deficienteIntelectual')?.value ),
      
      tempoResidencia: parseInt(trabalhador?.get('tempoResidencia')?.value ),
      condicaoIngresso: parseInt(trabalhador?.get('estadocondicaoIngressoCivil')?.value )
    }
  }


  transformDate(date:Date) {
    return formatDate(date, 'yyyy-MM-dd', this.locale, 'GMT+8' );
  }

  parseBoolForSimOrNao( bool: Boolean ){
    if ( bool == false ){
      return 'N';
    }else if (bool == true ){
      return 'S';
    }else{
      return 'N';
    }
  };

  parseSimOrNaoForBool( SimOrNao: string ){
    if (SimOrNao == 'N'){
      return false;
    }else if (SimOrNao == 'S'){
      return true;
    }else{
      return false;
    }
  };

  parseDate(dateString: string): Date {
    if (dateString) {
        return new Date(dateString);
    }
    return new Date();
  }

  setCpf(cpf: string) {
    cpf = String(cpf);

    if (cpf.length < 11) {
      for (let i = cpf.length; i < 11; i++) {
        cpf = `0${cpf}`;
      }
    }
    
    return cpf;
  }

}
