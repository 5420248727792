import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PageRequest } from '../../../modules/core/models/page-sort/page-request.model';
import { Sort } from '../../../modules/core/models/page-sort/sort.model';
import { Page } from '../../../modules/core/models/page-sort/page.model';
import { IPaginate } from '../../../modules/core/interfaces/paginate.interface';
import { IOrder } from '../../../modules/core/interfaces/order.interface';
import { SortDirection } from '../../../modules/core/models/page-sort/sort-direction.enum';
import { TrabalhadorService } from 'src/app/modules/core/services/trabalhador/trabalhador.service';
import { Trabalhador } from 'src/app/modules/core/models/tsv/trabalhador.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-trabalhador-list',
  templateUrl: './trabalhador-list.component.html'
})
export class TrabalhadorListComponent implements OnInit, IPaginate, IOrder {

  public pageRequest: PageRequest;
  public sort: Sort;
  public page: Page<Trabalhador>;
  public loadingTrabalhador: boolean = false;
  public pg: string = 'trabalhador';

  public searchResults: Trabalhador[];

  constructor(private router: Router,
    private toastr: ToastrService,
    private trabalhadorService: TrabalhadorService) {
  }

  ngOnInit(): void {
    this.limparEPesquisar();
  }

  getContent(): Trabalhador[] {
    if (this.page && this.page.content) {
      return this.page.content;
    } else {
      return [];
    }
  }

  limparEPesquisar() {
    this.sort = new Sort('id', SortDirection.ASCENDING);
    this.pageRequest = new PageRequest(0, 10, this.sort);
    this.pesquisar();
  }

  pesquisar() {
    this.loadingTrabalhador = true;
    this.trabalhadorService.page(this.pg, this.pageRequest).subscribe(
      (response) => {
        this.page = response;
      },
      (error) => {
        this.toastr.error('Não foi possível listar os Trabalhadors.');
      }
    ).add(() => {
      this.loadingTrabalhador = false;
    });
  }

  addTrabalhador(): void {
    this.router.navigate([`trabalhador/add`]);
  }

  editTrabalhador(trabalhador: Trabalhador): void {
    this.router.navigate([`trabalhador/${trabalhador.id}/edit`]);
  }

  deleteTrabalhador(trabalhador: Trabalhador): void {
    Swal.fire({
      title: "Tem certeza desta ação?",
      text: "Ela não poderá ser desfeita!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Deletar!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.loadingTrabalhador = true;
        this.trabalhadorService.delete(trabalhador.id as number, this.pg).subscribe({
          next: (response) => {
            console.log("AO Deletar", response);
            this.limparEPesquisar();
          },
          error: (error) => {
            this.toastr.error(error.error.message);
            this.loadingTrabalhador = false;
          },
          complete: () => {
            Swal.fire("Trabalhador Sem Vinculo", "Trabalhador deletado com sucesso!", "success");
            this.loadingTrabalhador = false;
          },
        });
      }
    });
  }

  changeOrder(column: string, direction: SortDirection): void {
    this.pageRequest.sort = new Sort(column, direction);
    this.pesquisar();
  }

  changePageNumber(pageNumber: number): void {
    this.pageRequest.page = pageNumber - 1;
    this.pesquisar();
  }

  changeSize(size: number): void {
    this.pageRequest.page = 0;
    this.pageRequest.size = size;
    this.pesquisar();
  }
}