<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Perfil</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/']" class="text-success">Início</a></li>
          <li class="breadcrumb-item active">Perfil</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <div class="card card-success card-outline">
          <div class="card-body box-profile">
            <div class="text-center">
              <img class="user-image img-circle elevation-2" alt="Gravatar" ngxGravatar [email]="emailCorporativo()" />
            </div>

            <h3 class="profile-username text-center">
              {{ userName }}
            </h3>

            <p class="text-muted text-center">
                <span *ngFor="let perfil of perfis">
                    {{ perfil.descricao }}
                  <br>
                </span>
            </p>

            <ul class="list-group list-group-unbordered mb-3">
              <li class="list-group-item">
                <b>Órgão:</b>
                <a class="float-right text-success">
                  {{ $any(vinculo)?.unidadeAdministrativaOficial?.orgaoRH.sigla }}
                </a>
              </li>
              <li class="list-group-item">
                <b>Unidade Administrativa:</b>
                <a class="float-right text-success">
                  {{ $any(vinculo)?.unidadeAdministrativaOficial?.descricao }}
                </a>
              </li>
              <li class="list-group-item">
                <b>Cargo:</b>
                <a class="float-right text-success">
                  {{ $any(vinculoVagaAtuais)[0]?.cargo.descricao }}
                </a>
              </li>
              <li class="list-group-item">
                <b>Regime:</b>
                <a class="float-right text-success">
                  {{ $any(vinculo)?.regimeJuridicoCodigo }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">Informações Pessoais</h3>
          </div>
          <div class="card-body">
            <ul class="list-group list-group-unbordered mb-3">
              <li class="list-group-item">
                <b>Nome:</b>
                <a class="float-right text-success">
                  {{ givenName }}
                </a>
              </li>
              <li class="list-group-item">
                <b>CPF:</b>
                <a class="float-right text-success">
                  {{ cpf | mask: '000.000.000-00' }}
                </a>
              </li>
              <li class="list-group-item">
                <b>Data Nascimento:</b>
                <a class="float-right text-success">
                  {{ $any(vinculo)?.pessoaFisica?.dataNascimento | date: 'dd/MM/yyyy' }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
