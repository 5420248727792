import {Component, ViewEncapsulation } from '@angular/core';
import {OAuthErrorEvent, OAuthService} from 'angular-oauth2-oidc';
import {JwksValidationHandler} from 'angular-oauth2-oidc-jwks';
import {authConfig} from './modules/core/oauth2.config';
import {SessionStorageService} from './modules/core/services/session-storage/session-storage.service';
import {AppService} from './modules/core/services/app.service';
import {environment} from '../environments/environment';
import {AuthService} from './modules/core/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    title = 'TSVNet';

    constructor(private oauthService: OAuthService,
                private authService: AuthService,
                private sessionStorageService: SessionStorageService,
                private appService: AppService) {

        this.oauthService.configure(authConfig);

        /** enable below validation only if jwks object is defined as part of oauthconfig obj */
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        this.oauthService.setStorage(sessionStorageService.getSessionStorage());

        this.oauthService.loadDiscoveryDocument()
            .then(res => {
                this.oauthService.sessionCheckIFrameUrl += `?client_id=${environment.sso.clientId}&redirect_uri=${window.location.origin}`;
                // @ts-ignore
                this.oauthService.config.sessionCheckIFrameUrl = this.oauthService.sessionCheckIFrameUrl;
                return;
            });

        /** commented below because below resource is protected by some identity server ex: wso2 */
        this.oauthService.loadDiscoveryDocumentAndTryLogin({})
            .then(
                () => this.appService.loadAccessData()
            );

        this.oauthService.events.subscribe(event => {
            if (event instanceof OAuthErrorEvent) {
                this.oauthService.logOut();
            }
        });
    }
}
