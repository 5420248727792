import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { NgxLoadingModule } from "ngx-loading";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MainComponent } from "./modules/main/main.component";
import { FooterComponent } from "./modules/main/footer/footer.component";
import { HeaderComponent } from "./modules/main/header/header.component";
import { MessagesComponent } from "./modules/main/header/messages/messages.component";
import { NotificationsComponent } from "./modules/main/header/notifications/notifications.component";
import { UserComponent } from "./modules/main/header/user/user.component";
import { MenuSidebarComponent } from "./modules/main/menu-sidebar/menu-sidebar.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { MenuItemComponent } from "./components/menu-item/menu-item.component";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import { DropdownMenuComponent } from "./components/dropdown/dropdown-menu/dropdown-menu.component";

import { OAuthModule } from "angular-oauth2-oidc";
import { environment } from "../environments/environment";
import { HasPermissionDirective } from "./modules/core/directives/has-permission.directive";
import { RemoveWrapperDirective } from "./modules/core/directives/remove-wrapper.directive";
import { PagePaginationComponent } from "./components/page-pagination/page-pagination.component";
import { PageSortingComponent } from "./components/page-sorting/page-sorting.component";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { GravatarModule } from "ngx-gravatar";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { TrabalhadorFormComponent } from "./pages/trabalhador/trabalhador-form/trabalhador-form.component";
import { TrabalhadorIdentificationComponent } from "./pages/trabalhador/trabalhador-identification/trabalhador-identification.component";
import { TrabalhadorDependenteComponent } from "./pages/trabalhador/trabalhador-dependente/trabalhador-dependente.component";
import { TrabalhadorContratoComponent } from "./pages/trabalhador/trabalhador-contrato/trabalhador-contrato.component";
import { TrabalhadorEnderecoComponent } from "./pages/trabalhador/trabalhador-endereco/trabalhador-endereco.component";
import { TrabalhadorListComponent } from "./pages/trabalhador/trabalhador-list/trabalhador-list.component";
import { FichaFinanceiraListComponent } from "./pages/ficha-financeira/ficha-financeira-list/ficha-financeira-list.component";
import { FichaFinanceiraFormsComponent } from "./pages/ficha-financeira/ficha-financeira-form/ficha-financeira-form.component";
import { FichaFinanceiraIdentificationComponent } from './pages/ficha-financeira/ficha-financeira-identification/ficha-financeira-identification.component';
import { FichaFinanceiraItemsComponent } from './pages/ficha-financeira/ficha-financeira-items/ficha-financeira-items.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    ProfileComponent,
    DashboardComponent,
    MessagesComponent,
    UserComponent,
    NotificationsComponent,
    MessagesComponent,
    NotificationsComponent,
    MenuItemComponent,
    DropdownComponent,
    DropdownMenuComponent,
    HasPermissionDirective,
    RemoveWrapperDirective,
    PagePaginationComponent,
    PageSortingComponent,
    TrabalhadorFormComponent,
    TrabalhadorIdentificationComponent,
    TrabalhadorDependenteComponent,
    TrabalhadorContratoComponent,
    TrabalhadorEnderecoComponent,
    TrabalhadorListComponent,
    FichaFinanceiraListComponent,
    FichaFinanceiraFormsComponent,
    FichaFinanceiraIdentificationComponent,
    FichaFinanceiraItemsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    GravatarModule,
    NgbModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
    NgxLoadingModule.forRoot({}),
    NgxMaskModule.forRoot(maskConfig),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl, environment.apiPortalAcessos, environment.apiPortalMenusSistemas, environment.apiRhVinculos],
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: "BRL",
    },
    {
      provide: LOCALE_ID,
      useValue: "pt-BR",
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
