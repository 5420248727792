import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AbstractCrudService } from "../abstract-crud.service";
import { FichaFinanceira } from "../../models/tsv/ficha_financeira/ficha_financeira";

@Injectable({
  providedIn: "root",
})
export class FichaFinanceiraService extends AbstractCrudService<FichaFinanceira, number> {
  constructor(protected override http: HttpClient) {
    super(http, `${environment.apiUrl}`);
  }

  count(): Observable<number> {
    return this.http.get<number>(`${this.base}/ficha-financeira/all`);
  }
}
