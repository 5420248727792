<nav class="main-header navbar navbar-expand navbar-dark navbar-success border-bottom-0">
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" (click)="toggleMenuSidebar.emit()" role="button"><i class="fas fa-bars"></i></a>
        </li>
    </ul>

    <form class="form-inline ml-3" *ngIf="false">
        <div class="input-group input-group-sm">
            <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
            <div class="input-group-append">
                <button class="btn btn-navbar" type="submit">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>
    </form>

    <ul class="navbar-nav ml-auto">
        <!-- <app-messages></app-messages>
        <app-notifications></app-notifications> -->
        <app-user></app-user>
        <!-- <li class="nav-item">
      <a
        class="nav-link"
        data-widget="control-sidebar"
        data-slide="true"
        href="#"
        role="button"
      >
        <i class="fas fa-th-large"></i>
      </a>
    </li> -->
    </ul>
</nav>