<app-header (toggleMenuSidebar)="toggleMenuSidebar()"></app-header>

<app-menu-sidebar class="main-sidebar elevation-4 sidebar-light-success"
></app-menu-sidebar>

<div class="content-wrapper">
    <router-outlet></router-outlet>
</div>

<app-footer class="main-footer"></app-footer>
<aside class="control-sidebar control-sidebar-dark"></aside>
<div id="sidebar-overlay" (click)="toggleMenuSidebar()"></div>
