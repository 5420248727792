<app-dropdown class="user-menu">
    <ng-container dropdown-button>
        <img class="user-image img-circle elevation-2" alt="Gravatar" ngxGravatar [email]="emailCorporativo()" />
    </ng-container>
    <ng-container dropdown-menu>
        <li class="user-header bg-success">
            <img class="img-circle elevation-2" alt="Gravatar" ngxGravatar [email]="emailCorporativo()" />
            <p>
                <a [routerLink]="['/profile']">{{ user.name }}</a>
                <small>
                    <span>{{ user.email }}</span>
                </small>
            </p>
        </li>
        <!-- Menu Footer-->
        <li class="user-footer">
            <form method="POST" [action]="logoutUrl" id="xForm" name="xForm" (ngSubmit)="onSubmit($event)">
                <input type='hidden' name='id_token_hint' value="{{idToken}}"/>
                <input type='hidden' name='post_logout_redirect_uri' value='{{urlRedirect}}'/>
                <button class="btn btn-default btn-flat" type='submit'>Sair</button>
            </form>
        </li>
    </ng-container>
</app-dropdown>
