<form *ngIf="formfichaFinanceira.get('fichaFinanceira')" [formGroup]="formfichaFinanceira">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

  <div formGroupName="fichaFinanceira">
    <ng-template #rtTrabalhador let-trabalhador="result" let-t="term">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <p class="my-0 mx-0 text-sm">{{ trabalhador.nome }}</p>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #rtContratos let-contratos="result" let-t="term">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <p class="my-0 mx-0 text-sm">{{ contratos.observacao }}</p>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="container-fluid">
      <div class="row">
        <div class="col-3">
          <label for="trabalhador" class="col-form-label">Trabalhador</label>
          <input formControlName="trabalhadorForm" id="trabalhador" type="text" class="form-control"
            [ngbTypeahead]="searchTrabalhadores" [inputFormatter]="formatterTrabalhadores"
            [resultTemplate]="rtTrabalhador" (change)="getContratos()" />
        </div>
        <input formControlName="id" type="hidden" class="form-control" id="id" />
        <div class="col-6">
          <label for="mes" class="col-form-label">Contrato</label>
          <input formControlName="contrato" id="contrato" type="text" class="form-control"
            [ngbTypeahead]="searchContratos" [inputFormatter]="formatterContratos" [resultTemplate]="rtContratos" />
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="mes" class="col-form-label">Mês pagamento</label>
          <input formControlName="numeroMesPagamento" type="text" class="form-control" id="mes" placeholder="00"
            [mask]="'M0'" placeholder="Mês Pagamento" />
        </div>
        <div class="col-3">
          <label for="ano" class="col-form-label">Ano pagamento</label>
          <input formControlName="numeroAnoPagamento" type="text" class="form-control" id="ano" placeholder="00"
            [mask]="'0000'" placeholder="Ano Pagamento" />
        </div>
        <div class="col-3 ">
          <div class="chk-ativo">
            <input formControlName="status" class="checkbox" type="checkbox" id="status" />
            <label class="col-form-label " for="status">Status ativo?</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid text-right pt-3">
    <input type="button" [routerLink]="['/ficha-financeira']" value="Cancelar" class="btn btn-warning mr-3" />
    <input type="submit" (click)="salvarFicha()" value="Salvar" class="btn btn-success" />
  </div>
</form>