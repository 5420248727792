<form *ngIf="formTrabalhador.get('trabalhador')" [formGroup]="formTrabalhador">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

  <div formGroupName="trabalhador">
    <!-- TEMPLATE PAÍS SEARCH -->
    <ng-template #rtPais let-pais="result" let-t="term">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <p class="my-0 mx-0 text-sm">{{ pais.nome }}</p>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="container-fluid">
      <div class="row">
        <div class="col-3">
          <label for="cpf" class="col-form-label">CPF</label>
          <input formControlName="cpf" type="text" class="form-control" id="cpf" placeholder="000.000.000-00"
            [mask]="'000.000.000-00'" />
        </div>
        <div class="col-6">
          <label for="name" class="col-form-label">Nome</label>
          <input formControlName="nome" type="text" class="form-control" id="name" />
        </div>
        <div class="col-3">
          <label for="socialName" class="col-form-label">Nome Social</label>
          <input formControlName="nomeSocial" type="text" class="form-control" id="socialName" />
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="dataNascimento" class="col-form-label">Data de Nascimento</label>
          <input formControlName="dataNascimento" type="date" class="form-control" id="dataNascimento" />
        </div>
        <div class="col-3">
          <label for="sexo" class="col-form-label">Sexo</label>
          <select formControlName="sexo" class="form-control" id="sexo">
            <option *ngFor="let sexo of data.sexos" [value]="sexo.value">
              {{ sexo.viewValue }}
            </option>
          </select>
        </div>
        <div class="col-3">
          <label for="raca" class="col-form-label">Raça/Cor</label>
          <select formControlName="racaCor" class="form-control" id="raca">
            <option *ngFor="let raca of data.racasCores" [value]="raca.value">
              {{ raca.viewValue }}
            </option>
          </select>
        </div>
        <div class="col-3">
          <label for="estadoCivil" class="col-form-label">Estado Civil</label>
          <select formControlName="estadoCivil" class="form-control" id="estadoCivil">
            <option *ngFor="let estadoCivil of data.estadoCivil" [value]="estadoCivil.value">
              {{ estadoCivil.viewValue }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="grauInstrucao" class="col-form-label">Grau de Instrução</label>
          <select formControlName="grauInstrucao" class="form-control" id="grauInstrucao">
            <option *ngFor="let grauInstrucao of data.grausInstrucao" [value]="grauInstrucao.value">
              {{ grauInstrucao.viewValue }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label for="paisNascimento" class="col-form-label">Pais de Nascimento</label>
          <input formControlName="paisNascimento" id="paisNascimento" type="text" class="form-control"
            [ngbTypeahead]="searchPais" [inputFormatter]="formatterPais" [resultTemplate]="rtPais" />
        </div>
        <div class="col-6">
          <label for="paisNacionalidade" class="col-form-label">Pais de Nacionalidade</label>
          <input formControlName="paisNacionalidade" id="paisNacionalidade" type="text" class="form-control"
            [ngbTypeahead]="searchPais" [inputFormatter]="formatterPais" [resultTemplate]="rtPais" />
        </div>
      </div>

      <div class="row py-2 mt-1">
        <div class="col-3">
          <div class="form-check">
            <input formControlName="resideExterior" type="checkbox" class="form-check-input" id="resideExterior">
            <label class="form-check-label" for="resideExterior">Reside no Exterior?</label>
          </div>
        </div>

        <div class="col-3">
          <div class="form-check">
            <input formControlName="possuiDependentes" type="checkbox" class="form-check-input" id="possuiDependentes">
            <label class="form-check-label" for="possuiDependentes">Possui Dependentes?</label>
          </div>
        </div>
        <div class="col-3">
          <div class="form-check">
            <input formControlName="possuiDeficiencia" class="form-check-input" type="checkbox" id="possuiDeficiencia">
            <label class="form-check-label" for="possuiDeficiencia">Possui Deficiência?</label>
          </div>
        </div>
        <div class="col-3">
          <div class="form-check">
            <input formControlName="deficienteIntelectual" class="form-check-input" type="checkbox"
              id="deficienteIntelectual">
            <label class="form-check-label" for="deficienteIntelectual">Deficiente Intelectual?</label>
          </div>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-3">
          <div class="form-check">
            <input formControlName="deficienteFisico" class="form-check-input" type="checkbox" id="deficienteFisico">
            <label class="form-check-label" for="deficienteFisico">Deficiente Físico?</label>
          </div>
        </div>
        <div class="col-3">
          <div class="form-check">
            <input formControlName="deficienteVisual" class="form-check-input" type="checkbox" id="deficienteVisual">
            <label class="form-check-label" for="deficienteVisual">Deficiente Visual?</label>
          </div>
        </div>
        <div class="col-3">
          <div class="form-check">
            <input formControlName="deficienteAuditivo" class="form-check-input" type="checkbox"
              id="deficienteAuditivo">
            <label class="form-check-label" for="deficienteAuditivo">Deficiente Auditivo?</label>
          </div>
        </div>
        <div class="col-3">
          <div class="form-check">
            <input formControlName="deficienteMental" class="form-check-input" type="checkbox" id="deficienteMental">
            <label class="form-check-label" for="deficienteMental">Deficiente Mental?</label>
          </div>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-3">
          <div class="form-check">
            <input formControlName="imigrante" class="form-check-input" type="checkbox" id="imigrante">
            <label class="form-check-label" for="imigrante">É Imigrante?</label>
          </div>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-3">
          <label class="form-check-label" for="tempoResidencia">Tempo de Residencia</label>
          <select formControlName="tempoResidencia" class="form-control" id="tempoResidencia">
            <option *ngFor="let tempoResidencia of data.tempoDeResidencia" [value]="tempoResidencia.value">
              {{ tempoResidencia.viewValue }}
            </option>
          </select>
        </div>
        <div class="col-9">
          <label class="form-check-label" for="condicaoIngresso">Condição de Ingresso</label>
          <select formControlName="condicaoIngresso" class="form-control" id="condicaoIngresso">
            <option *ngFor="let condicaoIngresso of data.condicaoIngressoImigrante" [value]="condicaoIngresso.value">
              {{ condicaoIngresso.viewValue }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</form>