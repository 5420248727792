import { Component, Inject, Input, LOCALE_ID, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable, OperatorFunction, debounceTime, map } from "rxjs";
import { PageRequest } from "src/app/modules/core/models/page-sort/page-request.model";
import { Page } from "src/app/modules/core/models/page-sort/page.model";
import { SortDirection } from "src/app/modules/core/models/page-sort/sort-direction.enum";
import { Sort } from "src/app/modules/core/models/page-sort/sort.model";
import { FichaFinanceira } from "src/app/modules/core/models/tsv/ficha_financeira/ficha_financeira";
import { ItemFichaFinanceira } from "src/app/modules/core/models/tsv/ficha_financeira/item_ficha_financeira ";
import { Rubricas } from "src/app/modules/core/models/tsv/rubricas/rubricas.model";
import { ItemFichaFinanceiraService } from "src/app/modules/core/services/ficha-financeira/item-ficha-financeira.service";
import { RubricasService } from "src/app/modules/core/services/rubricas/rubricas.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-ficha-financeira-items",
  templateUrl: "./ficha-financeira-items.component.html",
  styleUrls: ["./ficha-financeira-items.component.scss"],
})
export class FichaFinanceiraItemsComponent implements OnInit {
  @Input() fichaFinanceira: FichaFinanceira;
  @Input() formfichaFinanceira: FormGroup;

  public loading: boolean = false;
  public editing: boolean = false;
  public andNew: boolean = false;
  public itemFichaFinanceira: ItemFichaFinanceira;
  public itemFichaFinanceiraList: Array<ItemFichaFinanceira> = Array();
  pagamentoPorHora = false;

  rubrica: Rubricas;
  rubricas: Rubricas[];

  id_: number;

  public pageRequest: PageRequest;
  public sort: Sort;
  public loadingDependente: boolean = false;
  public pg: string = "item-ficha-financeira";
  public page: Page<ItemFichaFinanceira>;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private service: ItemFichaFinanceiraService,
    private activatedRoute: ActivatedRoute,
    private _rubricasService: RubricasService,
    private _Service: ItemFichaFinanceiraService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.id_ = parseInt(String(this.activatedRoute.snapshot.paramMap.get("id")));
    this.limparEPesquisar();
    this.getRubricas();
  }

  limparEPesquisar() {
    this.sort = new Sort("id", SortDirection.ASCENDING);
    this.pageRequest = new PageRequest(0, 10, this.sort);
    this.pesquisar();
  }

  pesquisar() {
    this.loading = true;
    this.service
      .findAllById(this.pageRequest, this.id_)
      .subscribe(
        (response) => {
          this.page = response;
        },
        (error) => {
          this.toastr.error("Não foi possível listar os Fichas Financeiras.");
        }
      )
      .add(() => {
      });
  }

  setItensFicha() {
    this.itemFichaFinanceira = new ItemFichaFinanceira();
  }

  createForm(): void {
    if (!this.formfichaFinanceira?.get("itensFichaFinanceira")) {
      this.setItensFicha();

      (this.formfichaFinanceira as FormGroup).addControl(
        "itensFichaFinanceira",
        this.formBuilder.group({
          id: [this.itemFichaFinanceira.id],
          descricao: [this.itemFichaFinanceira.descricao],
          observacao: [this.itemFichaFinanceira.observacao],
          codigoRubrica: [this.itemFichaFinanceira.codigoRubrica],
          valorItemPagamento: [this.itemFichaFinanceira.valorItemPagamento],
          indiPagamentoHora: [this.itemFichaFinanceira.indiPagamentoHora],
          quantidadeHora: [this.itemFichaFinanceira.quantidadeHora],
          tipoItemFichaFinanceira: [this.itemFichaFinanceira.tipoItemFichaFinanceira],
          rubrica: [""],
        })
      );
    }
  }

  getRubricas() {
    this.loading = true;
    this._rubricasService.findAll().subscribe({
      next: (response: any): any => {
        this.rubricas = response;
        this.loading = false;
      },
      error: (error) => {
        this.toastr.error(error.error.message);
        this.loading = false;
      },
    });
  }

  getContent() {
    if (this.page && this.page.content) {
      return this.page.content;
    } else {
      return [];
    }
  }

  searchRubricas: OperatorFunction<string, readonly { id: number; nome: string }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map((term) => (term === "" ? [] : this.rubricas.filter((rubrica) => rubrica.nome.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))
    );

  formatterRubricas = (rubrica: { id: number; nome: string }) => {
    return `${rubrica.id} - ${rubrica.nome}`;
  };

  getData(): Object {
    this.fichaFinanceira = new FichaFinanceira();
    this.fichaFinanceira.id = this.id_;
    return {
      id: parseInt(String(this.formfichaFinanceira.controls.itensFichaFinanceira.value.id)),
      descricao: String(this.formfichaFinanceira.controls.itensFichaFinanceira.value.descricao),
      observacao: String(this.formfichaFinanceira.controls.itensFichaFinanceira.value.observacao),
      codigoRubrica: parseInt(this.formfichaFinanceira.controls.itensFichaFinanceira.value.rubrica.id),
      valorItemPagamento: parseInt(this.formfichaFinanceira.controls.itensFichaFinanceira.value.valorItemPagamento),
      indiPagamentoHora: this.parseBoolForSimOrNao(this.formfichaFinanceira.controls.itensFichaFinanceira.value.indiPagamentoHora),
      quantidadeHora: parseInt(this.formfichaFinanceira.controls.itensFichaFinanceira.value.quantidadeHora),
      tipoItemFichaFinanceira: "A",
      fichaFinanceira: this.fichaFinanceira,
    };
  }

  add() {
    let values = this.getData() as ItemFichaFinanceira;
    this.loading = true;
    if (this.id_ > 0) {
      if (this.andNew) {
        this._Service.save(values, this.pg).subscribe({
          next: (response) => {
            console.log("AO GRAVAR", response);
            this.pesquisar();
          },
          error: (error) => {
            this.toastr.error(error.error.message);
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
            Swal.fire("Trabalhador Sem Vinculo", "Salvo com sucesso!", "success");
            this.cancelar();
          },
        });
      }
    } else {
      Swal.fire("Trabalhador Sem Vinculo", "Salve primeiro os dados principais", "warning");
      this.loading = false;
    }

    this.loading = false;
  }

  cancelar() {
    this.formfichaFinanceira.get("itensFichaFinanceira")?.reset();
    this.editing = false;
    this.andNew = false;
  }

  newItem() {
    this.formfichaFinanceira.get("itensFichaFinanceira")?.reset();
    this.editing = true;
    this.andNew = true;
  }

  editar(value: ItemFichaFinanceira) {
    let itemFicha = this.formfichaFinanceira.get("itensFichaFinanceira");
    itemFicha?.get("id")?.setValue(value.id);
    itemFicha?.get("descricao")?.setValue(value.descricao);
    itemFicha?.get("observacao")?.setValue(value.observacao);
    itemFicha?.get("codigoRubrica")?.setValue(value.codigoRubrica);
    itemFicha?.get("valorItemPagamento")?.setValue(value.valorItemPagamento);
    itemFicha?.get("indiPagamentoHora")?.setValue(this.parseSimOrNaoForBool(value.indiPagamentoHora));
    itemFicha?.get("quantidadeHora")?.setValue(value.quantidadeHora);
    itemFicha?.get("tipoItemFichaFinanceira")?.setValue(value.tipoItemFichaFinanceira);
    itemFicha?.get("rubrica")?.setValue(this.rubricas.find(x => x.id == value.codigoRubrica));
    console.log(this.rubricas.find(x => x.id == value.codigoRubrica))

    this.editing = true;
    this.andNew = true;
    console.log(value)
  }

  parseBoolForSimOrNao(bool: Boolean) {
    if (bool == false) {
      return "N";
    } else if (bool == true) {
      return "S";
    } else {
      return "N";
    }
  }

  parseSimOrNaoForBool(SimOrNao: string) {
    if (SimOrNao == "N") {
      return false;
    } else if (SimOrNao == "S") {
      return true;
    } else {
      return false;
    }
  }

  remItemFichaFinac(index: any) {
    Swal.fire({
      title: 'Você tem certeza?',
      text: "Você vai deletar permanetemente!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, Deletar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this._Service.delete(index as number, this.pg)
          .subscribe({
            next: (response) => {
              console.log('AO Deletar', response);
              this.pesquisar();
            },
            error: (error) => {
              this.toastr.error(error.error.detail);
            },
            complete: () => {
              Swal.fire('Trabalhador Sem Vinculo', 'Item da Ficha Financeira deletada com sucesso!', 'success');
            }

          })
      }
    })

  }

  porHora() {
    let itemFicha = this.formfichaFinanceira.get("itensFichaFinanceira");

    return itemFicha?.get("indiPagamentoHora")?.value;
  }
}
