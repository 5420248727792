import { Trabalhador } from "./trabalhador.model";

export class Dependente {
  id?: number;
  tipoDependente: number;
  nomeDependente: string;
  dataNascDependente: Date;
  cpf: number;
  deducaoIRRF: string = 'N';
  salarioFamilia: string = 'N';
  trabalhador: Trabalhador;

}