import { Component, OnInit, Input, LOCALE_ID, Inject, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Pais } from "src/app/modules/core/models/tsv/pais.model";
import { Trabalhador } from "src/app/modules/core/models/tsv/trabalhador.model";
import { PaisService } from "src/app/modules/core/services/pais/pais.service";
import { TrabalhadorService } from "src/app/modules/core/services/trabalhador/trabalhador.service";
import { formatDate, Location } from "@angular/common";
import Swal from "sweetalert2";
import { MensagensService } from "src/app/modules/core/services/mensagens.service";

@Component({
  selector: "app-trabalhador-form",
  templateUrl: "./trabalhador-form.component.html",
  styleUrls: ["./trabalhador-form.component.scss"],
})
export class TrabalhadorFormComponent implements OnInit {
  @Output()
  @Input()
  trabalhadorForm: FormGroup;


  @Input()
  isDisabled: boolean = false;

  public trabalhador: Trabalhador;
  public id_: number;
  public pg: string = "trabalhador";
  public loading: boolean = false;
  public loaded: boolean = false;
  public tabActive: number = 1;

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private service: TrabalhadorService,
    private paisService: PaisService,
    private activatedRoute: ActivatedRoute,
    private _router: Router,
    private _location: Location,
    private _mensagensService: MensagensService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit(): void {
    this.createForm();
    if (!this.activatedRoute.snapshot.paramMap.get("id")) {
      this.isDisabled = true;
    }
  }
  validarDados() {
    return this.trabalhadorForm.valid;
  }

  onSubmit() {
    if (!this.validarDados()) {
      this._mensagensService.exibirValidacaoCamposObrigatorios();
      return;
    } else {
      this.loading = true;
      this.id_ = parseInt(String(this.activatedRoute.snapshot.paramMap.get("id")));
      let values = this.getData();

      if (this.id_ > 0) {
        this.service.update(this.id_, values as Trabalhador, this.pg).subscribe({
          next: (response) => {
            console.log("AO GRAVAR", response);
          },
          error: (error) => {
            this.toastr.error(error.error.message);
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
            Swal.fire("Trabalhador Sem Vinculo", "Alterado com sucesso!", "success");
          },
        });
      } else {
        this.service.save(values as Trabalhador, this.pg).subscribe({
          next: (response) => {
            console.log("AO GRAVAR", response);
          },
          error: (error) => {
            this.toastr.error(error.error.message);
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
            Swal.fire("Trabalhador Sem Vinculo", "Inserido com sucesso!", "success");
            this._location.back();
          },
        });
      }
    }
  }

  getData(): Object {
    return {
      id: parseInt(String(this.activatedRoute.snapshot.paramMap.get("id"))),
      cpf: parseInt(this.trabalhadorForm.controls.trabalhador.value.cpf.replace(/[^a-z0-9]/gi, "")),
      nome: String(this.trabalhadorForm.controls.trabalhador.value.nome),
      sexo: this.trabalhadorForm.controls.trabalhador.value.sexo,
      racaCor: this.trabalhadorForm.controls.trabalhador.value.racaCor,
      estadoCivil: this.trabalhadorForm.controls.trabalhador.value.estadoCivil,
      tpGrauInstr: parseInt(this.trabalhadorForm.controls.trabalhador.value.grauInstrucao),
      nomeSocial: String(this.trabalhadorForm.controls.trabalhador.value.nomeSocial),
      dataNascimento: this.transformDate(this.trabalhadorForm.controls.trabalhador.value.dataNascimento),
      resideExterior: this.parseBoolForSimOrNao(this.trabalhadorForm.controls.trabalhador.value.resideExterior),
      imigrante: this.parseBoolForSimOrNao(this.trabalhadorForm.controls.trabalhador.value.imigrante),
      possuiDeficiencia: this.parseBoolForSimOrNao(this.trabalhadorForm.controls.trabalhador.value.possuiDeficiencia),
      possuiDependentes: this.parseBoolForSimOrNao(this.trabalhadorForm.controls.trabalhador.value.possuiDependentes),
      paisNascimento: parseInt(this.trabalhadorForm.controls.trabalhador.value.paisNascimento.id),
      paisNacionalidade: parseInt(this.trabalhadorForm.controls.trabalhador.value.paisNacionalidade.id),
      deficienteFisico: this.parseBoolForSimOrNao(this.trabalhadorForm.controls.trabalhador.value.deficienteFisico),
      deficienteVisual: this.parseBoolForSimOrNao(this.trabalhadorForm.controls.trabalhador.value.deficienteVisual),
      deficienteAuditivo: this.parseBoolForSimOrNao(this.trabalhadorForm.controls.trabalhador.value.deficienteAuditivo),
      deficienteMental: this.parseBoolForSimOrNao(this.trabalhadorForm.controls.trabalhador.value.deficienteMental),
      deficienteIntelectual: this.parseBoolForSimOrNao(this.trabalhadorForm.controls.trabalhador.value.deficienteIntelectual),

      tempoResidencia: parseInt(this.trabalhadorForm.controls.trabalhador.value.tempoResidencia),
      condicaoIngresso: parseInt(this.trabalhadorForm.controls.trabalhador.value.condicaoIngresso),
    };
  }

  parseSimOrNaoForBool(SimOrNao: string) {
    if (SimOrNao == "N") {
      return false;
    } else if (SimOrNao == "S") {
      return true;
    } else {
      return false;
    }
  }

  parseBoolForSimOrNao(bool: Boolean) {
    if (bool == false) {
      return "N";
    } else if (bool == true) {
      return "S";
    } else {
      return "N";
    }
  }

  transformDate(date: Date) {
    return formatDate(date, "yyyy-MM-dd", this.locale, 'GMT+8');
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return new Date();
  }
  createForm() {
    if (undefined === this.trabalhadorForm) {
      this.trabalhadorForm = this.fb.group({
        id: [parseInt(String(this.activatedRoute.snapshot.paramMap.get("id")))],
      });
    }
  }
}
