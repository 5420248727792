import { HttpClient } from "@angular/common/http";
import { ItemContrato } from "../../models/tsv/contrato/itemContrato.model";
import { AbstractCrudService } from "../abstract-crud.service";
import { environment } from "src/environments/environment";
import { PageRequest } from "../../models/page-sort/page-request.model";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Page } from "../../models/page-sort/page.model";

@Injectable({
  providedIn: "root",
})
export class ItemContratoService extends AbstractCrudService<ItemContrato, number> {
  constructor(protected override http: HttpClient) {
    super(http, `${environment.apiUrl}`);
  }

  findAllById(pageRequest?: PageRequest, id_contrato?: number): Observable<Page<ItemContrato>> {
    const params: { [key: string]: any } = !pageRequest
      ? {}
      : {
          page: pageRequest.page,
          size: pageRequest.size,
          sort: pageRequest.sort.column + "," + pageRequest.sort.direction,
        };

    return this.http.get<Page<ItemContrato>>(`${this.base}/item-contrato/paginado/${id_contrato}`, { params: params });
  }
}
