<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Ficha Financeira</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a class="text-success">Home</a></li>
          <li class="breadcrumb-item active">Ficha Financeira</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

  <form [formGroup]="fichaFinanceiraForm">
    <div>
      <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="tabActive">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Identificação</a>
          <ng-template ngbNavContent class="px-4 py-4">
            <app-ficha-financeira-identification [formfichaFinanceira]="fichaFinanceiraForm">
            </app-ficha-financeira-identification>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" [disabled]="isDisabled">
          <a ngbNavLink>Itens da Ficha</a>
          <ng-template ngbNavContent class="px-4 py-4">
            <app-ficha-financeira-items [formfichaFinanceira]="fichaFinanceiraForm"></app-ficha-financeira-items>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="px-3 py-3 bg-white border border-top-0 rounded-bottom"></div>
    </div>
  </form>
</section>