<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Ficha Financeira</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/']" class="text-success">Home</a></li>
          <li class="breadcrumb-item active">Ficha Financeira</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <div class="container-fluid">
    <div class="card card-success">
      <div class="overlay" *ngIf="_loading">
        <i class="spinner-border text-success big-rem"></i>
      </div>
      <div class="card-header">
        <h3 class="card-title">Lista de Fichas Financeiras</h3>
      </div>
      <div class="card-body">
        <div class="row" *ngIf="page">
          <div class="col-sm-12">
            <div class="form-group table-responsive">
              <table class="table table-bordered table-hover text-nowrap">
                <thead>
                  <tr>
                    <app-page-sorting [title]="'ID'" [property]="'id'" [class]="'column-min'" [page]="page" [iOrder]="this" remove-wrapper> </app-page-sorting>
                    <app-page-sorting [title]="'Nome'" [property]="'nome'" [class]="'column-min'" [page]="page" [iOrder]="this" remove-wrapper> </app-page-sorting>
                    <app-page-sorting [title]="'CPF'" [class]="'column-min'" remove-wrapper> </app-page-sorting>
                    <app-page-sorting [title]="''" [class]="'column-min'" remove-wrapper> </app-page-sorting>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let ficha of getContent()">
                    <td>{{ficha.id }}</td>
                    <td>{{ficha.contrato.trabalhador.nome }}</td>
                    <td>{{ficha.contrato.trabalhador.cpf }}</td>
                    <td class="py-0 align-middle">
                      <button class="btn btn-default btn-sm" (click)="editar(ficha)">
                        <i class="fas fa-edit"></i>
                        Editar
                      </button>
                      <button class="btn btn-default btn-sm" (click)="remFichaFinac(ficha?.id)">
                        <i class="fas fa-trash"></i>
                        Excluir
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="page">
          <app-page-pagination [page]="page" [iPaginate]="this"></app-page-pagination>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-success" (click)="addFicha()">Novo</button>
      </div>
    </div>
  </div>
</section>
