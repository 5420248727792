import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import { Trabalhador } from 'src/app/modules/core/models/tsv/trabalhador.model';
import { TrabalhadorService } from 'src/app/modules/core/services/trabalhador/trabalhador.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  searchResults: Array<Trabalhador> = [];
  loading: boolean = false;

  constructor(private router: Router,
              private toastr: ToastrService,
              private trabalhadorService: TrabalhadorService) {
  }

  ngOnInit(): void {
    this.findAllTrabalhadors();
  }

  findAllTrabalhadors(): void {
    this.loading = true;

    this.trabalhadorService.count()
    .subscribe({
      next: (response: any) => {
        this.searchResults = response;
      },
      error: (error) => {
        console.log('Error:', error)
      },
      complete: () => {
        this.loading = false;
      }
    })
    
    /*
    .subscribe(
        (data: any) => {
          this.searchResults = data;
        },
        err => this.toastr.error(`Código: ${err.error.status} - ${err.error.error}`)
      );
      */
  }

}
