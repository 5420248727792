<form *ngIf="formTrabalhador.get('enderecos')" [formGroup]="formTrabalhador">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

  <div class="container-fluid rounded py-2" formGroupName="enderecos">

    <!-- ADICIONA NOVO ENDEREÇO -->
    <div class="row" *ngIf="!editing">
      <div class="col text-right">
        <button class="btn btn-secondary btn-sm" (click)="newEndereco()">
          <i class="fa fa-plus"></i> Novo Endereço
        </button>
      </div>
    </div>

    <!-- LISTA DE ENDEREÇOS -->
    <div class="row mt-4" *ngIf="!editing">
      <div class="col">
        <table class="table table-sm table-striped">
          <thead>
            <tr class="">
              <th>ID</th>
              <th>CEP</th>
              <th>Descrição</th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let endereco of getContent(); index as i;">
              <td>{{ endereco?.id }}</td>
              <td>{{ endereco?.codgCep }}</td>
              <td>{{ endereco?.descComplEndereco }}</td>
              <td class="text-center">
                <button class="btn btn-default btn-sm" (click)="editEndereco(endereco)">
                  <i class="fas fa-edit"></i>
                  Editar
                </button>
                <button class="btn btn-default btn-sm" (click)="removeEndereco(endereco?.id)">
                  <i class="fas fa-trash"></i>
                  Excluir
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- FIM DA LISTA DE ENDEREÇOS -->


    <!-- FORMULÁRIO DE INCLUSÃO DE ENDEREÇO -->
    <div *ngIf="editing">
      <div class="row">
        <input formControlName="id" type="hidden" class="form-control" id="id" />
        <div class="col-3">
          <label for="cep" class="col-form-label">CEP</label>
          <input formControlName="cep" type="text" class="form-control" id="cep" placeholder="00000-000"
            [mask]="'00000-000'" (blur)="consultaEndereco($event)" />
        </div>
        <div class="col-9">
          <label for="logradouro" class="col-form-label">Logradouro</label>
          <input formControlName="logradouro" type="text" class="form-control" id="logradouro"
            [readonly]="loadLogradouro" />
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="numero" class="col-form-label">Número</label>
          <input formControlName="numero" type="text" class="form-control" id="numero" />
        </div>
        <div class="col-3">
          <label for="quadra" class="col-form-label">Quadra</label>
          <input formControlName="quadra" type="text" class="form-control" id="quadra" />
        </div>
        <div class="col-3">
          <label for="lote" class="col-form-label">Lote</label>
          <input formControlName="lote" type="text" class="form-control" id="lote" />
        </div>
        <div class="col-3">
          <label for="bairro" class="col-form-label">Bairro</label>
          <input formControlName="bairro" type="text" class="form-control" id="bairro" [readonly]="loadBairro" />
        </div>
        <div class="col-3">
          <label for="complemento" class="col-form-label">Complemento</label>
          <input formControlName="complemento" type="text" class="form-control" id="complemento"
            [readonly]="loadComplemento" />
        </div>
        <div class="col-3">
          <label for="tipoEndereco" class="col-form-label">Tipo de Endereço * </label>
          <select formControlName="tipoEndereco" class="form-control" id="tipoEndereco">
            <option *ngFor="let tipo of data.tipoEndereco " [value]="tipo.value">
              {{ tipo.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label for="estado" class="col-form-label">Estado</label>
          <select formControlName="estado" [attr.disabled]="loadEstado ? '' : null" class="form-control" id="estado"
            (change)="filterMunicipios($event)">
            <option *ngFor="let estado of data.estados " [value]="estado.value">
              {{ estado.viewValue }}
            </option>
          </select>
        </div>

        <div class="col-6">
          <label for="municipio" class="col-form-label">Município</label>
          <select [attr.disabled]=" loadMunicipios ? '' : null" formControlName="municipio" class="form-control"
            id="municipio">
            <option *ngFor="let municipio of cidades " [value]="municipio.value">
              {{ municipio.viewValue }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2" *ngIf="editing">
    <div class="col text-right">
      <button class="btn btn-sm btn-info" (click)="addEndereco()">
        <i class="fa fa-check"></i> Associar Endereço
      </button>
      <button class="btn btn-danger btn-sm ml-1" (click)="cancelEdit()">
        <i class="fa fa-ban"></i> Cancelar
      </button>
    </div>
  </div>
</form>