import { Component, Input, OnInit } from '@angular/core';
import {SortDirection} from "../../modules/core/models/page-sort/sort-direction.enum";
import {BasePage} from "../../modules/core/models/page-sort/base-page.model";
import {IOrder} from "../../modules/core/interfaces/order.interface";

@Component({
  selector: 'app-page-sorting',
  templateUrl: './page-sorting.component.html',
  styleUrls: ['./page-sorting.component.scss']
})
export class PageSortingComponent implements OnInit {

  public directionAsc: SortDirection = SortDirection.ASCENDING;
  public directionDesc: SortDirection = SortDirection.DESCENDING;

  @Input() title: string;
  @Input() property: string;
  @Input() class: string;
  @Input() page: BasePage;
  @Input() iOrder: IOrder;

  constructor() { }

  ngOnInit(): void {
  }

  changeOrder(column: string, direction: SortDirection) {
    this.iOrder.changeOrder(column, direction);
  }
}
