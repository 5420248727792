import { Component, Inject, Input, LOCALE_ID, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { FichaFinanceira } from "src/app/modules/core/models/tsv/ficha_financeira/ficha_financeira";
import { FichaFinanceiraService } from "src/app/modules/core/services/ficha-financeira/ficha-financeira.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-ficha-financeira-form",
  templateUrl: "./ficha-financeira-form.component.html",
  styleUrls: ["./ficha-financeira-form.component.scss"],
})
export class FichaFinanceiraFormsComponent implements OnInit {
  @Input()
  fichaFinanceiraForm: FormGroup;

  @Input()
  isDisabled: boolean = false;

  public _fichaFinanceira: FichaFinanceira;
  public id_: number;
  public pg: string = "ficha-financeira";
  public loading: boolean = false;
  public loaded: boolean = false;
  public tabActive: number = 1;

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit(): void {
    this.createForm();
    if (!this.activatedRoute.snapshot.paramMap.get("id")) {
      this.isDisabled = true;
    }
  }

  createForm() {
    if (undefined === this.fichaFinanceiraForm) {
      this.fichaFinanceiraForm = this.fb.group({
        id: [parseInt(String(this.activatedRoute.snapshot.paramMap.get("id")))],
      });
    }
    this.isDisabled = false;
  }
}
