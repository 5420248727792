<form *ngIf="formTrabalhador.get('contratos')" [formGroup]="formTrabalhador">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

  <!-- Actions -->
  <div class="container-fluid rounded py-2" formGroupName="contratos">
    <!-- TEMPLATE rubrica SEARCH -->
    <div class="card">
      <div class="card-body">
        <ng-template #rtRubrica let-rubrica="result" let-t="term">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <p class="my-0 mx-0 text-sm">{{ rubrica.nome }}</p>
              </div>
            </div>
          </div>
        </ng-template>

        <!-- ADICIONA NOVO CONTRATO -->
        <div class="row" *ngIf="!editing">
          <div class="col text-right">
            <button class="btn btn-secondary btn-sm" (click)="newContrato()"><i class="fa fa-plus"></i> Novo
              Contrato</button>
          </div>
        </div>

        <!-- LISTA DE Contratos -->
        <div class="row mt-4" *ngIf="!editing">
          <div class="col">
            <table class="table table-sm table-striped">
              <thead>
                <tr class="">
                  <th>Id</th>
                  <th>Nome Trabalhador</th>
                  <th>Cargo</th>
                  <th>Cpf Supervisor</th>
                  <th>Informações</th>
                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let contrato of getContent(); index as i">
                  <td>{{ contrato.id }}</td>
                  <td>{{ contrato.trabalhador.nome }}</td>
                  <td>{{ contrato.nomeCargo }}</td>
                  <td>{{ contrato.numeroCpfSupervisorEstagio }}</td>
                  <td>{{ contrato.observacao }}</td>
                  <td class="text-center">
                    <button class="btn btn-default btn-sm" (click)="editContrato(contrato)">
                      <i class="fas fa-edit"></i>
                      Editar
                    </button>
                    <!-- <button class="btn btn-default btn-sm" (click)="removerContrato(contrato?.id)">
                      <i class="fas fa-trash"></i>
                      Excluir
                    </button> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- FIM DA LISTA DE CONTRATOS -->

        <div *ngIf="editing">
          <div class="row">
            <input formControlName="id" type="hidden" class="form-control" id="id" />
            <div class="col-2">
              <label for="nomeCargo" class="col-form-label">Nome do Cargo</label>
              <input formControlName="nomeCargo" type="text" class="form-control" id="nomeCargo" />
            </div>
            <div class="col-4">
              <label for="observacao" class="col-form-label">Observação</label>
              <input formControlName="observacao" type="text-area" class="form-control" id="observacao" />
            </div>
            <div class="col-2">
              <label for="dataInicioContrato" class="col-form-label">Data Inicio Contrato</label>
              <input formControlName="dataInicioContrato" type="date" class="form-control" id="dataInicioContrato" />
            </div>
            <div class="col-2">
              <label for="dataFimContrato" class="col-form-label">Data Fim Contrato</label>
              <input formControlName="dataFimContrato" type="date" class="form-control" id="dataFimContrato" />
            </div>
          </div>
          <div class="row align-items-end">
            <div class="col-3">
              <label for="numeroCpfSupervisorEstagio" class="col-form-label">CPF Supervisor</label>
              <input formControlName="numeroCpfSupervisorEstagio" type="text" class="form-control"
                id="numeroCpfSupervisorEstagio" placeholder="000.000.000-00" [mask]="'000.000.000-00'" />
            </div>
            <div class="col-3">
              <label for="codigoDecisaoJudicial" class="col-form-label">Código Decisão Judicial</label>
              <input formControlName="codigoDecisaoJudicial" type="text" class="form-control" id="codigoDecisaoJudicial"
                [mask]="'000000000000000'" />
            </div>
            <div class="col-3">
              <label for="codigoOrgao" class="col-form-label">Código do Orgão</label>
              <input formControlName="codigoOrgao" type="text" class="form-control" id="codigoOrgao"
                [mask]="'000000000'" />
            </div>
            <div class="col-3">
              <label for="codigoUnidadeAdm" class="col-form-label">Código Unidade Administrativa</label>
              <input formControlName="codigoUnidadeAdm" type="text" class="form-control" id="codigoUnidadeAdm"
                [mask]="'000000000'" />
            </div>
          </div>
          <div class="row align-items-end">
            <div class="col-3">
              <label for="numeroCnpjEstagio" class="col-form-label">CNPJ Estágio</label>
              <input formControlName="numeroCnpjEstagio" type="text" class="form-control" id="numeroCnpjEstagio"
                placeholder="00.000.000/0000-00" [mask]="'00.000.000/0000-00'" />
            </div>
            <div class="col-2 pb-2">
              <div class="form-check ml-1">
                <input formControlName="indiEstagio" type="checkbox" class="form-check-input" id="indiEstagio" />
                <label class="form-check-label" for="indiEstagio">Indicador de Estágio</label>
              </div>
            </div>
            <div class="col-2 pb-2">
              <div class="form-check ml-1">
                <input formControlName="indiNaturezaEstagio" type="checkbox" class="form-check-input"
                  id="indiNaturezaEstagio" />
                <label class="form-check-label" for="indiNaturezaEstagio">Indicador de Natureza Estágio</label>
              </div>
            </div>
            <div class="col-2 pb-2">
              <label for="indiNivelInstrucaoEstagio" class="col-form-label">Indicador Nível Instrução Estágio</label>
              <select formControlName="indiNivelInstrucaoEstagio" class="form-control" id="indiNivelInstrucaoEstagio">
                <option *ngFor="let tipo of data.indiNivelInstrucaoEstagio" [value]="tipo.value">
                  {{ tipo.description }}
                </option>
              </select>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-3">
              <label for="numeroApoliceSeguro" class="col-form-label">Numero de Apólice de Seguro </label>
              <input formControlName="numeroApoliceSeguro" type="text" class="form-control" id="numeroApoliceSeguro"
                placeholder="00000000000000" [mask]="'00000000000000'" />
            </div>
            <div class="col-6">
              <label for="infoEstagioAreaAtuacao" class="col-form-label">Área de Atuação </label>
              <textarea formControlName="infoEstagioAreaAtuacao" type="text" class="form-control"
                id="infoEstagioAreaAtuacao" [maxlength]="200"> </textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <label for="codigoCboTrabalhador" class="col-form-label">Código CBO </label>
              <input formControlName="codigoCboTrabalhador" type="text" class="form-control" id="codigoCboTrabalhador"
                [mask]="'000000'" />
            </div>
            <div class="col-2">
              <label for="codigoCategoriaTabalhador" class="col-form-label">Código Categoria Tabalhador </label>
              <input formControlName="codigoCategoriaTabalhador" type="text" class="form-control"
                id="codigoCategoriaTabalhador" [mask]="'000'" />
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <label for="codigoBancoTrabalhador" class="col-form-label">Código do Banco </label>
              <input formControlName="codigoBancoTrabalhador" type="text" class="form-control"
                id="codigoBancoTrabalhador" [mask]="'000000000000000'" />
            </div>
            <div class="col-2">
              <label for="codigoAgenciaTrabalhador" class="col-form-label">Código Agência do Banco </label>
              <input formControlName="codigoAgenciaTrabalhador" type="text" class="form-control"
                id="codigoAgenciaTrabalhador" [mask]="'000000000000000'" />
            </div>
            <div class="form-check ml-1">
              <input formControlName="statusContrato" type="checkbox" class="form-check-input" id="statusContrato" />
              <label class="form-check-label" for="statusContrato">Status do Contrato</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- item Contrato -->

  <div class="container-fluid rounded py-2" formGroupName="itemContrato">
    <div class="card" *ngIf="editing && !andNew">
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <label for="codigoRubrica" class="col-form-label">Rubrica</label>
            <input formControlName="rubrica" id="codigoRubrica" type="text" class="form-control"
              [ngbTypeahead]="searchRubricas" [inputFormatter]="formatterRubricas" [resultTemplate]="rtRubrica" />
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <label for="valorItemContrato" class="col-form-label">Valor Item</label>
            <input formControlName="valorItemContrato" maxlength="11" prefix="R$" mask="separator.2"
              thousandSeparator="." type="text" class="form-control" id="valorItemContrato" />
          </div>
          <div class="col-4">
            <label for="DescricaoObservacao" class="col-form-label">Observação</label>
            <input formControlName="descricaoObservacao" type="text-area" class="form-control"
              id="DescricaoObservacao" />
          </div>
          <div class="col-1">
            <label for="QtdParcelaContrato" class="col-form-label">Qtd Parcelas</label>
            <input formControlName="qtdParcelaContrato" type="text" maxlength="4" mask="0000" class="form-control"
              id="QtdParcelaContrato" />
          </div>
          <div class="col-5">
            <button class="btn btn-sm btn-info btnAddRubrica" (click)="AddRubrica()"><i class="fa fa-check"></i> Add
              Item</button>
          </div>
        </div>

        <div class="row mt-4" *ngIf="editing">
          <div class="col">
            <table class="table table-sm table-striped">
              <thead>
                <tr class="">
                  <th>Id</th>
                  <th>Valor Item</th>
                  <th>Qtd Parcelas</th>
                  <th>Observação</th>
                  <th>Código Rubrica</th>
                  <th>Nome Rubrica</th>
                  <th>Tipor Rubrica</th>
                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let itemContrato of getItensContrato(); index as i">
                  <td>{{ itemContrato.id }}</td>
                  <td>{{ itemContrato.valorItemContrato | currency }}</td>
                  <td>{{ itemContrato.qtdparcelacontrato }}</td>
                  <td>{{ itemContrato.descricaoObservacao }}</td>
                  <td>{{ itemContrato.codigoRubrica }}</td>
                  <td>{{ itemContrato.descricaoItemContrato }}</td>
                  <td>{{ itemContrato.tipoItemContrato }}</td>
                  <td class="text-center">
                    <button class="btn btn-default btn-sm" (click)="ExcluirRubrica(itemContrato)">
                      <i class="fas fa-trash"></i>
                      Excluir
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2" *ngIf="editing">
    <div class="col text-right">
      <button class="btn btn-sm btn-info" (click)="addContrato()"><i class="fa fa-check"></i> Associar Contrato</button>
      <button class="btn btn-danger btn-sm ml-1" (click)="editing = false"><i class="fa fa-ban"></i> Cancelar</button>
    </div>
  </div>
</form>