export const environment = {
  production: false,
  authorizationUrl: 'https://localhost:9443/api/identity/entitlement/decision',
  apiUrl: '/smart-api',
  apiPortalAcessos: '/portal-acessos',
  apiPortalMenusSistemas: '/portal-menus',
  apiRhVinculos: '/rh-vinculos',
  idSistemaPortal: 'TSV',
  sso: {
    clientId: 'jnp61cXdj8lQSWLp7hJmAUmH4Nca',
    serverUrl: 'https://localhost:9443',
    issuer: '/oauth2/oidcdiscovery',
    redirectUri: window.location.origin,
    scope: 'openid email profile',
    showDebugInformation: true,
    sessionChecksEnabled: false,
    urlCheckSession: '/oidc/checksession',
    responseType: 'code',
  }
};