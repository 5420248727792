import { Component, Input, OnInit } from '@angular/core';
import {BasePage} from "../../modules/core/models/page-sort/base-page.model";
import {IPaginate} from "../../modules/core/interfaces/paginate.interface";

@Component({
  selector: 'app-page-pagination',
  templateUrl: './page-pagination.component.html',
  styleUrls: ['./page-pagination.component.scss']
})
export class PagePaginationComponent implements OnInit {

  @Input() page: BasePage;
  @Input() iPaginate: IPaginate;

  public sizes = [5, 10, 15, 25, 50, 100];

  constructor() { }

  ngOnInit(): void {
  }

  changePageNumber(pageNumber: number){
    this.iPaginate.changePageNumber(pageNumber);
  }

  changeSize(size: number){
    this.iPaginate.changeSize(size);
  }

}
