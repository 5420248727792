export class Uteis {
  public static trataPagina(pagina: number): number {
    return pagina > 0 ? pagina - 1 : 0;
  }

  public static trataVar(_var: any, def: any): any {
    return _var == null || _var == undefined ? def : _var;
  }

  public static limitarConteudo(conteudo: string, tamanho: number) {
    if (!conteudo || conteudo == null) {
      return "";
    }
    if (conteudo.length <= tamanho) {
      return conteudo;
    } else {
      conteudo = conteudo.substring(0, tamanho);
      return conteudo + "...";
    }
  }

  public static contains(conteudo: string, comparar: string): boolean {
    if (conteudo == null || comparar == null) {
      return false;
    }
    return conteudo.indexOf(comparar) >= 0;
  }

  public static scrollar(): void {
    window.scrollTo({ top: 1, behavior: "smooth" });
  }
}
