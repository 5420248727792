export class ItemFichaFinanceira {
  id?: number;
  descricao: string;
  observacao: string;
  indiPagamentoHora: string;
  quantidadeHora: number;
  valorItemPagamento: number;
  codigoRubrica: number;
  tipoItemFichaFinanceira: string;
}
