import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Trabalhador } from "../../models/tsv/trabalhador.model";
import { AbstractCrudService } from "../abstract-crud.service";

@Injectable({
  providedIn: 'root'
})
export class TrabalhadorService extends AbstractCrudService< Trabalhador, number> {
  constructor(protected override http: HttpClient) {
    super(http, `${environment.apiUrl}`);
  }

  count(): Observable<number> {
    return this.http.get<number>(`${this.base}/trabalhador/all`);
  }
}

