<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link navbar-success">
    <img
        src="assets/img/brasao-goias-colorido.svg"
        alt="TSV"
        class="brand-image img-circle elevation-3"
        style="opacity: 0.8"
    />
    <span class="brand-text font-weight-light">TSVNet 1.0.0</span>
</a>

<!-- Sidebar -->
<div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
            <img class="img-circle elevation-2" alt="Gravatar" ngxGravatar [email]="emailCorporativo()" />
        </div>
        <div class="info">
            <a [routerLink]="['/profile']" class="d-block">
                {{ user.name }}
            </a>
        </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
        >
            <app-menu-item
                *ngFor="let item of menu"
                [menuItem]="item"
            ></app-menu-item>
        </ul>
    </nav>
</div>
