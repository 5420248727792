<form *ngIf="formfichaFinanceira.get('itensFichaFinanceira')" [formGroup]="formfichaFinanceira">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

  <div class="row" *ngIf="!editing">
    <div class="col text-right">
      <button class="btn btn-secondary btn-sm" (click)="newItem()"><i class="fa fa-plus"></i> Novo Item</button>
    </div>
  </div>

  <ng-template #rtRubrica let-rubrica="result" let-t="term">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <p class="my-0 mx-0 text-sm">{{ rubrica.nome }}</p>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- LISTA DE item ficha -->
  <div class="row mt-4" *ngIf="!editing">
    <div class="col">
      <table class="table table-sm table-striped">
        <thead>
          <tr class="">
            <th>ID</th>
            <th>Código Rubrica</th>
            <th>Descrição</th>
            <th>Observação</th>
            <th>Valor</th>
            <th class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of getContent(); index as i;">
            <td>{{ item?.id }}</td>
            <td>{{ item?.codigoRubrica }}</td>
            <td>{{ item?.descricao }}</td>
            <td>{{ item?.observacao }}</td>
            <td>{{ item?.valorItemPagamento }}</td>
            <td class="text-center">
              <button class="btn btn-default btn-sm" (click)="editar(item)">
                <i class="fas fa-edit"></i>
                Editar
              </button>
              <button class="btn btn-default btn-sm" (click)="remItemFichaFinac(item?.id)">
                <i class="fas fa-trash"></i>
                Excluir
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="container-fluid rounded py-2" formGroupName="itensFichaFinanceira">
    <div class="card" *ngIf="editing && andNew">
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <label for="codigoRubrica" class="col-form-label">Rubrica</label>
            <input formControlName="rubrica" id="codigoRubrica" type="text" class="form-control"
              [ngbTypeahead]="searchRubricas" [inputFormatter]="formatterRubricas" [resultTemplate]="rtRubrica" />
          </div>
          <div class="col-2">
            <label class="checkbox-inline check-pagamento" for="indiPagamentoHora">
              <input formControlName="indiPagamentoHora" class="checkbox-inline" type="checkbox"
                id="indiPagamentoHora" /> Pagamento por Hora?
            </label>
          </div>
          <div *ngIf="porHora()" class="col-2">
            <label for="quantidadeHora" class="col-form-label">Qtd Horas</label>
            <input onchange="quantidadeHora = !quantidadeHora" formControlName="quantidadeHora" type="text"
              maxlength="2" mask="00" class="form-control" id="quantidadeHora" />
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <label for="valorItemPagamento" class="col-form-label">Valor Item</label>
            <input formControlName="valorItemPagamento" maxlength="11" prefix="R$" mask="separator.2"
              thousandSeparator="." type="text" class="form-control" id="valorItemContrato" />
          </div>
          <div class="col-4">
            <label for="DescricaoObservacao" class="col-form-label">Observação</label>
            <input formControlName="observacao" type="text-area" class="form-control" id="DescricaoObservacao" />
          </div>
          <div class="col-4">
            <label for="descricao" class="col-form-label">Descrição</label>
            <input formControlName="descricao" type="text-area" class="form-control" id="descricao" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2" *ngIf="andNew">
    <div class="col text-right">
      <button class="btn btn-sm btn-info" (click)="add()"><i class="fa fa-check"></i> Salvar</button>
      <button class="btn btn-alert btn-sm ml-1" (click)="cancelar()"><i class="fa fa-ban"></i> Cancelar</button>
    </div>
  </div>
</form>