import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../../auth/auth.service";
import { environment } from '../../../../../environments/environment';
import { Pais } from "../../models/tsv/pais.model";

@Injectable({
  providedIn: "root"
})
export class PaisService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  findAll() {
    return this.http.get<Pais>(`${environment.apiUrl}/pais/`)
  }

}