import condicaoIngressoImigrante from "./condicaoIngressoImigrante.json";
import estadoCivil from "./estadoCivil.json";
import grausInstrucao from "./grauInstrucao.json";
import racasCores from "./racas.json";
import sexos from "./sexos.json";
import tipoDependente from "./tipoDependente.json";
import estados from "./estados.json";
import municipios from "./municipios.json";
import tempoDeResidencia from "./tempoDeResidencia.json";
import indiNivelInstrucaoEstagio from "./indiNivelInstrucaoEstagio.json";
import tipoEndereco from "./tipoEndereco.json";

export {
  condicaoIngressoImigrante,
  estadoCivil,
  grausInstrucao,
  racasCores,
  sexos,
  tipoDependente,
  estados,
  municipios,
  tempoDeResidencia,
  indiNivelInstrucaoEstagio,
  tipoEndereco,
};
