import { Injectable } from "@angular/core";
import { Contrato } from "../../models/tsv/contrato/contrato.model";
import { HttpClient } from "@angular/common/http";
import { PageRequest } from "../../models/page-sort/page-request.model";
import { Page } from "../../models/page-sort/page.model";
import { Observable } from "rxjs";
import { AbstractCrudService } from "../abstract-crud.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ContratoService extends AbstractCrudService<Contrato, number> {
  constructor(protected override http: HttpClient) {
    super(http, `${environment.apiUrl}`);
  }

  findAllById(
    pageRequest?: PageRequest,
    id_trabalhador?: number
  ): Observable<Page<Contrato>> {
    const params: { [key: string]: any } = !pageRequest
      ? {}
      : {
          page: pageRequest.page,
          size: pageRequest.size,
          sort: pageRequest.sort.column + "," + pageRequest.sort.direction,
        };

    return this.http.get<Page<Contrato>>(
      `${this.base}/contrato/paginado/${id_trabalhador}`,
      { params: params }
    );
  }

  count(): Observable<number> {
    return this.http.get<number>(`${this.base}/contrato/all`);
  }
}
