import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, EMPTY} from 'rxjs';
import {AuthService} from '../../auth/auth.service';
import {environment} from '../../../../../environments/environment';
import {Vinculo} from '../../models/rhnet/vinculo.model';

@Injectable({
    providedIn: 'root'
})
export class RhNetService {

    constructor(private http: HttpClient,
                private authService: AuthService) {
    }

    findViculoByCpf(): Observable<Vinculo> {
        let cpf = this.authService.getCpf();
        return undefined !== cpf 
                    ? this.http.get<Vinculo>(`${environment.apiRhVinculos}/${cpf}`, ) 
                    : EMPTY;
    }
}
