import { Dependente } from "./dependente.model";
import { Pais } from "./pais.model";

export class Trabalhador {
  id?: number;
  cpf: number;
  nome: string;
  sexo: string;
  racaCor: string;
  estadoCivil: string;
  tpGrauInstr: number;
  nomeSocial: string;
  dataInicioAtividade: Date;
  dataFimAtividade: Date;
  dataNascimento: Date;
  resideExterior: string;
  imigrante: string;
  possuiDeficiencia: string;
  possuiDependentes: string;
  estagioVoluntario: string;
  paisNascimento: number;
  paisNacionalidade: number;
  nomeCargo: string;
  cboTrabalhador: number;
  codgOrgao: number;
  codgBancoTrabador: number;
  codgAgenciaTrabdor: number;
  deficienteFisico: string;
  deficienteVisual: string;
  deficienteAuditivo: string;
  deficienteMental: string;
  deficienteIntelectual: string;
  tempoResidencia: number;
  condicaoIngresso: number;
}