import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from 'src/environments/environment';
import {Dependente} from "../../../../modules/core/models/tsv/dependente.model";
import {AbstractCrudService} from "../../../../modules/core/services/abstract-crud.service";
import { PageRequest } from "../../models/page-sort/page-request.model";
import { Page } from "../../models/page-sort/page.model";

@Injectable({
  providedIn: 'root'
})
export class DependenteService extends AbstractCrudService<Dependente, number> {
  constructor(protected override http: HttpClient) {
    super(http, `${environment.apiUrl}`);
  }

  findAllById( pageRequest?: PageRequest, id_trabalhador?: number ): Observable<Page<Dependente>> {
    const params: { [key: string]: any } = !pageRequest ? {} : {
      page: pageRequest.page,
      size: pageRequest.size,
      sort: pageRequest.sort.column + ',' + pageRequest.sort.direction
    };

    return this.http.get<Page<Dependente>>(`${this.base}/dependente/paginado/${id_trabalhador}`, { params: params })
  }

  count(): Observable<number> {
    return this.http.get<number>(`${this.base}/dependente/all`);
  }

}
