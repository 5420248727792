import { Component, Input, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Endereco } from 'src/app/modules/core/models/tsv/endereco.model';
import { Trabalhador } from 'src/app/modules/core/models/tsv/trabalhador.model';
import { EnderecoService } from 'src/app/modules/core/services/endereco/endereco.service';
import { PageRequest } from '../../../modules/core/models/page-sort/page-request.model';
import { Sort } from '../../../modules/core/models/page-sort/sort.model';
import { Page } from '../../../modules/core/models/page-sort/page.model';
import { SortDirection } from '../../../modules/core/models/page-sort/sort-direction.enum';
import { formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import {
  estados,
  municipios,
  tipoEndereco,
} from '../../../../assets/data'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-trabalhador-endereco',
  templateUrl: './trabalhador-endereco.component.html',
  styleUrls: ['./trabalhador-endereco.component.scss']
})
export class TrabalhadorEnderecoComponent implements OnInit {

  @Input()
  trabalhador: Trabalhador;

  @Input()
  formTrabalhador: FormGroup;

  @Input()
  isDisabled: boolean;

  public loading: boolean = false;
  public endereco: Endereco;
  public enderecos: Array<Endereco> = Array();
  public cidades: any[];
  public estado: string;
  public editing: boolean = false;
  public andNew: boolean = false;
  public id_: number;
  public loadLogradouro: boolean = false;
  public loadBairro: boolean = false;
  public loadComplemento: boolean = false;
  public loadEstado: boolean = false;
  public loadMunicipios: boolean = true;

  public pageRequest: PageRequest;
  public sort: Sort;
  public loadingDependente: boolean = false;
  public pg: string = 'endereco';
  public page: Page<Endereco>;


  public data = {
    estados,
    municipios,
    tipoEndereco
  }

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private enderecoService: EnderecoService,
    private activatedRoute: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  newEndereco() {
    this.formTrabalhador.get('enderecos')?.reset();
    this.editing = true;
    this.andNew = true;
  }

  private validarEndeco() {
    let enderecosAb = this.formTrabalhador.get('enderecos');

    if (!enderecosAb?.get('cep')?.value) {
      Swal.fire('Trabalhador Sem Vinculo', 'O CEP do Endereço é Obrigatório!', 'warning');
      return false;
    }

    if (!enderecosAb?.get('tipoEndereco')?.value) {
      Swal.fire('Trabalhador Sem Vinculo', 'Tipo Endereço é Obrigatório!', 'warning');
      return false;
    }

    return true;
  }

  addEndereco() {
    if (!this.validarEndeco()) {
      return;
    }

    let enderecosAb = this.formTrabalhador.get('enderecos');
    let data = enderecosAb?.value;
    this.enderecos.push(data);
    this.endereco = new Endereco();

    this.endereco.codgCep = parseInt(String(enderecosAb?.get('cep')?.value));
    this.endereco.numrImovel = enderecosAb?.get('numero')?.value;
    this.endereco.numrQuadra = enderecosAb?.get('quadra')?.value;
    this.endereco.numrLote = enderecosAb?.get('lote')?.value;
    this.endereco.descComplEndereco = enderecosAb?.get('complemento')?.value;
    this.endereco.descricaoBairro = enderecosAb?.get('bairro')?.value;
    this.endereco.descricaoLogradouro = enderecosAb?.get('logradouro')?.value;
    this.endereco.tipoEndereco = enderecosAb?.get('tipoEndereco')?.value;
    this.endereco.trabalhador = this.getDataTrbalhador() as Trabalhador;

    this.loading = true;
    this.id_ = parseInt(String(this.activatedRoute.snapshot.paramMap.get('id')));

    if (this.id_ > 0) {
      if (this.andNew) {
        this.enderecoService.save(this.endereco as Endereco, this.pg)
          .subscribe({
            next: (response) => {
              console.log('AO GRAVAR', response);
            },
            error: (error) => {
              this.toastr.error(error.error.message);
              this.loading = false;
            },
            complete: () => {
              Swal.fire('Trabalhador Sem Vinculo', 'Endereço inserido com sucesso!', 'success');
              this.cancelEdit();
            }

          })
      } else {
        this.endereco.id = parseInt(String(enderecosAb?.get('id')?.value));
        this.enderecoService.update(this.endereco.id as number, this.endereco, this.pg)
          .subscribe({
            next: (response) => {
              console.log('AO GRAVAR', response);
            },
            error: (error) => {
              this.toastr.error(error.error.message);
              this.loading = false;
            },
            complete: () => {
              this.loading = false;
              Swal.fire('Endereço Trabalhador Sem Vinculo', 'Alterado com sucesso!', 'success');
              this.cancelEdit();
            }
          })
      }
    } else {
      Swal.fire('Trabalhador Sem Vinculo', 'Salve primeiro os dados principais', 'warning');
      this.loading = false;
    }

    this.cancelEdit();
    this.loading = false;
  }

  editEndereco(endereco: Endereco) {
    let enderecos = this.formTrabalhador.get('enderecos');
    this.consultaEndereco(String(endereco.codgCep));

    enderecos?.get('id')?.setValue(endereco.id);
    enderecos?.get('cep')?.setValue(endereco.codgCep);
    enderecos?.get('numero')?.setValue(endereco.numrImovel);
    enderecos?.get('lote')?.setValue(endereco.numrLote);
    enderecos?.get('complemento')?.setValue(endereco.descComplEndereco);
    enderecos?.get('quadra')?.setValue(endereco.numrQuadra);
    enderecos?.get('bairro')?.setValue(endereco.descricaoBairro);
    enderecos?.get('logradouro')?.setValue(endereco.descricaoLogradouro);
    enderecos?.get('tipoEndereco')?.setValue(endereco.tipoEndereco);

    this.editing = true;
    this.andNew = false;
  }

  cancelEdit() {
    this.editing = false;
    this.formTrabalhador.get('enderecos')?.reset();
    this.loadBairro = false;
    this.loadComplemento = false;
    this.loadMunicipios = true;
    this.loadLogradouro = false;
    this.loadEstado = false;
    this.cidades = [];
    this.limparEPesquisar();
  }

  setEndereco() {
    this.endereco = new Endereco();
  }

  createForm(): void {
    this.limparEPesquisar();
    if (!this.formTrabalhador?.get('enderecos')) {
      this.setEndereco();

      (this.formTrabalhador as FormGroup)
        .addControl("enderecos", this.formBuilder.group({
          id: [this.endereco.id],
          estado: '',
          municipio: '',
          bairro: [this.endereco.descricaoBairro],
          logradouro: [this.endereco.descricaoLogradouro],
          numero: [this.endereco.numrImovel],
          quadra: [this.endereco.numrQuadra],
          lote: [this.endereco.numrLote],
          complemento: [this.endereco.descComplEndereco],
          cep: [this.endereco.codgCep],
          tipoLogradouro: [this.endereco.codgTipoLogradouro],
          enderecoExterior: [this.endereco.descEnderecoExterior],
          paisExterior: [this.endereco.codgPaisExterior],
          cidadeExterior: [this.endereco.descCidadeExterior],
          tipoEndereco: [this.endereco.tipoEndereco]
        }));
    }
  }

  limparEPesquisar() {
    this.sort = new Sort('id', SortDirection.ASCENDING);
    this.pageRequest = new PageRequest(0, 10, this.sort);
    this.pesquisar();
  }

  pesquisar() {

    this.id_ = parseInt(String(this.activatedRoute.snapshot.paramMap.get('id')));

    let value = this.getDataTrbalhador();

    this.loadingDependente = true;

    if (value !== null) {
      this.enderecoService.findAllById(this.pageRequest, this.id_).subscribe(
        (response) => {
          this.page = response;
        },
        (error) => {
          this.toastr.error('Não foi possível listar os Endereco do Trabalhador.');
        }
      ).add(() => {
        this.loadingDependente = false;
      });
    }
  }

  getContent(): Endereco[] {
    if (this.page && this.page.content) {
      return this.page.content;
    } else {
      return [];
    }
  }

  consultaEndereco(cep: any) {
    let cepValue: string;

    if (cep.target) {
      cepValue = cep.target.value.replace(/[^a-z0-9]/gi, '');
    } else {
      cepValue = cep.replace(/[^a-z0-9]/gi, '');
    }

    // Grupo de Inputs
    let enderecos = this.formTrabalhador.get('enderecos');

    if (cepValue.length >= 8) {
      this.loadingDependente = true;
      this.enderecoService.getEnderecoVia(cepValue)
        .subscribe({
          next: (response: any) => {
            if (response?.erro) {
              Swal.fire('Trabalhador Sem Vinculo', 'CEP inválido ou não encontrado', 'warning');
              enderecos?.get('cep')?.reset();
              console.error(response?.erro);
              return;
            }

            // Set Logradouro Value
            if (response.logradouro) {
              enderecos?.get('logradouro')?.setValue(response.logradouro);
              this.loadLogradouro = true;
            } else {
              enderecos?.get('logradouro')?.setValue('');
              this.loadLogradouro = false;
            }

            if (response.bairro) {
              enderecos?.get('bairro')?.setValue(response.bairro);
              this.loadBairro = true;
            } else {
              enderecos?.get('bairro')?.setValue('');
              this.loadBairro = false;
            }

            if (response.complemento) {
              enderecos?.get('complemento')?.setValue(response.complemento);
              this.loadComplemento = true;
            }

            // Set Estado Value
            let estadoValue: any = this.data.estados.filter(e => {
              return e.UF === response.uf;
            })[0];
            if (response.uf) {
              enderecos?.get('estado')?.setValue(estadoValue?.value);
              this.loadEstado = true;
            } else {
              this.loadEstado = false;
            }

            // Set Cidade Value
            this.filterMunicipios(response.uf, response.ibge)

            if (response.ibge) {
              enderecos?.get('municipio')?.setValue(response?.ibge);
              this.loadMunicipios = true;
            } else {
              this.loadMunicipios = false;
            }

          },
          error: (error) => {
            Swal.fire('Trabalhador Sem Vinculo', 'CEP inválido ou não encontrado', 'warning');
            enderecos?.get('cep')?.reset();
            console.error(error);
          },
          complete: () => { this.loadingDependente = false; }
        })
    } else {
      if (cepValue.length == 0) {
        enderecos?.reset();
        this.cidades = [];
      }
    }
  }

  filterMunicipios(estado: any, IbgeCity: any = null) {
    this.cidades = [];
    if (estado?.target?.value) {
      this.estado = estado.target.value
      this.loadMunicipios = false;
    } else {
      this.estado = this.data.estados.filter(e => {
        return e.UF == estado;
      })[0].value;
    }

    this.cidades = this.data.municipios.filter(m => {
      return m.uf == this.estado;
    });

    if (IbgeCity) {
      //let enderecos = this.formTrabalhador.get('enderecos');
      let municipioValue: any = this.cidades.filter(c => {
        return c.value == IbgeCity;
      })[0];
    }
  }

  removeEndereco(index: any) {
    this.enderecos.splice(index, 1);

    Swal.fire({
      title: 'Você tem certeza?',
      text: "Você vai deletar permanetemente!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, Deletar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.enderecoService.delete(index as number, this.pg)
          .subscribe({
            next: (response) => {
              console.log('AO Deletar', response);
            },
            error: (error) => {
              this.toastr.error(error.error.detail);
              this.loading = false;
            },
            complete: () => {
              Swal.fire('Trabalhador Sem Vinculo', 'Endereço deletado com sucesso!', 'success');
              this.cancelEdit();
            }
          })
      }
    })
  }

  transformDate(date: Date) {
    return formatDate(date, 'yyyy-MM-dd', this.locale, 'GMT+8');
  }

  parseBoolForSimOrNao(bool: Boolean) {
    if (bool == false) {
      return 'N';
    } else if (bool == true) {
      return 'S';
    } else {
      return 'N';
    }
  };


  getDataTrbalhador(): Object {

    let trabalhador = this.formTrabalhador.get('trabalhador');

    return {
      id: parseInt(String(this.activatedRoute.snapshot.paramMap.get('id'))),
      cpf: parseInt(trabalhador?.get('cpf')?.value.replace(/[^a-z0-9]/gi, '')),
      nome: String(trabalhador?.get('nome')?.value),
      sexo: trabalhador?.get('sexo')?.value,
      racaCor: trabalhador?.get('racaCor')?.value,
      estadoCivil: trabalhador?.get('estadoCivil')?.value,
      tpGrauInstr: parseInt(trabalhador?.get('grauInstrucao')?.value),
      nomeSocial: String(trabalhador?.get('nomeSocial')?.value),
      dataNascimento: this.transformDate(trabalhador?.get('dataNascimento')?.value),

      resideExterior: this.parseBoolForSimOrNao(trabalhador?.get('resideExterior')?.value),
      imigrante: this.parseBoolForSimOrNao(trabalhador?.get('imigrante')?.value),
      possuiDeficiencia: this.parseBoolForSimOrNao(trabalhador?.get('possuiDeficiencia')?.value),
      possuiDependentes: this.parseBoolForSimOrNao(trabalhador?.get('possuiDependentes')?.value),
      paisNascimento: parseInt(String(trabalhador?.get('paisNascimento')?.value.id)),
      paisNacionalidade: parseInt(String(trabalhador?.get('paisNacionalidade')?.value.id)),
      deficienteFisico: this.parseBoolForSimOrNao(trabalhador?.get('deficienteFisico')?.value),
      deficienteVisual: this.parseBoolForSimOrNao(trabalhador?.get('deficienteVisual')?.value),
      deficienteAuditivo: this.parseBoolForSimOrNao(trabalhador?.get('deficienteAuditivo')?.value),
      deficienteMental: this.parseBoolForSimOrNao(trabalhador?.get('deficienteMental')?.value),
      deficienteIntelectual: this.parseBoolForSimOrNao(trabalhador?.get('deficienteIntelectual')?.value),

      tempoResidencia: parseInt(trabalhador?.get('tempoResidencia')?.value),
      condicaoIngresso: parseInt(trabalhador?.get('estadocondicaoIngressoCivil')?.value)
    }
  }


}
