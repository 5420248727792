import { Component, OnInit } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { AuthService } from "../../core/auth/auth.service";

@Component({
  selector: "app-menu-sidebar",
  templateUrl: "./menu-sidebar.component.html",
  styleUrls: ["./menu-sidebar.component.scss"],
})
export class MenuSidebarComponent implements OnInit {
  public user: any;
  public menu = MENU;

  constructor(private oauthService: OAuthService, private authService: AuthService) {}

  ngOnInit() {
    const token = this.oauthService.getAccessToken();
    const claims = this.oauthService.getIdentityClaims();
    if (claims) {
      this.user = { name: (<any>claims)["sub"], email: (<any>claims)["email"], given_name: (<any>claims)["given_name"] };
    } else {
      this.user = { name: "", email: "", given_name: "" };
    }
  }

  emailCorporativo() {
    return (<any>this.authService).getEmailCorporativo();
  }
}

export const MENU = [
  {
    name: "Dashboard",
    path: ["/"],
  },
  {
    name: "Cadastros",
    children: [
      {
        name: "Trabalhador",
        path: ["/trabalhador"],
      },
    ],
  },
  {
    name: "Remuneração",
    children: [
      {
        name: "Ficha Financeira",
        path: ["/ficha-financeira"],
      },
    ],
  },
];
