<ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px' }"
></ngx-loading>

<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Dashboard</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a [routerLink]="['/']" class="text-success">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3>{{ searchResults.length }}</h3>
                        <p>Trabalhador Registrados</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <a [routerLink]="['/trabalhador']" class="small-box-footer"
                        >Mais info <i class="fas fa-arrow-circle-right"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section>
