import { Bairro } from "./bairro.model";
import { Municipio } from "./municipio.model";
import { Trabalhador } from "./trabalhador.model";

export class Endereco {
  id?: number;
  codgMunicipio: number;
  codgBairro: number;
  codgLogadouro: number;
  numrImovel: string;
  numrQuadra: string;
  numrLote: string;
  descComplEndereco: string;
  codgCep: number;
  codgTipoLogradouro: number;
  descEnderecoExterior: string;
  codgPaisExterior: number;
  numrEnderecoExterior: number;
  descCidadeExterior: number;
  descricaoBairro: string;
  descricaoLogradouro: string;
  tipoEndereco: string;
  trabalhador: Trabalhador;
}