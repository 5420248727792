<div class="login-box">
  <div class="login-logo">
    <a>
      <img src="assets/img/brasao-goias-colorido.svg" alt="Estado de Goiás" width="100" />
    </a>
  </div>
  <div class="card card-outline card-warning">
    <div class="card-body">
      <b>
        <p class="login-box-msg">Ocorreu um erro na aplicação.</p>
      </b>
      <p class="login-box-msg">Por favor contate o administrador do sistema.</p>

      <div class="social-auth-links text-center">
        <button class="btn btn-block btn-default">
          <i class="fas fa-home mr-2"></i>
          Início
        </button>
        <button type="button" class="btn btn-block btn-success">
          <i class="fas fa-sign-out-alt mr-2"></i>
          Sair
        </button>
      </div>

    </div>
  </div>
</div>
