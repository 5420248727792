import {Injectable} from '@angular/core';
import {forkJoin} from 'rxjs';
import {Menu} from "../../models/portal/menu.model";
import {PortalService} from "../portal/portal.service";
import {SessionStorageService} from "../session-storage/session-storage.service";
import {ActionID} from '../../models/portal/action-id.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private _menus: Menu[] = [];
  private _unidadesAcessos: ActionID[] = [];

  constructor(
    private portalService: PortalService,
    private sessionStorageService: SessionStorageService) { }

  getMenus(): Menu[] {
    return this._menus;
  }

  isEmpty(): boolean {
    return this.getMenus().length == 0;
  }

  loadMenus() {
    if (!this.sessionStorageService.isExist('app_menus')) {
      // let loadMenus = this.portalService.findMenus();
      let loadUnidadesAcesso = this.portalService.findActionsUsuario();
      forkJoin([loadUnidadesAcesso]).subscribe(results => {
        // this._menus = results[0];
        this._unidadesAcessos = results[0];
        // this.sessionStorageService.set('app_menus', results[0]);
        this.sessionStorageService.set('app_unidades_acesso', (<any>results)[1]);
      });
    } else {
      this._menus = this.sessionStorageService.get('app_menus');
      this._unidadesAcessos = this.sessionStorageService.get('app_unidades_acesso');
    }
  }
}
