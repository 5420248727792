import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {CrudOperations} from '../interfaces/crud-operations.interface';
import {Page} from '../models/page-sort/page.model';
import {PageRequest} from '../models/page-sort/page-request.model';

export abstract class AbstractCrudService<T, ID> implements CrudOperations<T, number> {
  protected constructor(
    protected http: HttpClient,
    protected base: string
  ) {}

  save(t: T, pg: string): Observable<T> {
    return this.http.post<T>(`${this.base}/${pg}`, t, {});
  }

  update(id: number, t: T, pg: string): Observable<T> {
    return this.http.put<T>(`${this.base}/${pg}` , t, {});
  }

  findOne(id: number, pg: string): Observable<T> {
    return this.http.get<T>(`${this.base}/${pg}/${id}`);
  }

  page( pg: string, pageRequest?: PageRequest): Observable<Page<T>> {
    const params: { [key: string]: any } = !pageRequest ? {} : {
      page: pageRequest.page,
      size: pageRequest.size,
      sort: pageRequest.sort.column + ',' + pageRequest.sort.direction
    };

    return this.http.get<Page<T>>(`${this.base}/${pg}/paginado`, {params: params})
  }

  findAll( pg: string ): Observable<Page<T>> {
    return this.http.get<Page<T>>(`${this.base}/${pg}/all`);
  }

  delete( id: number, pg: string): Observable<T> {
    return this.http.delete<T>(`${this.base}/${pg}/${id}`);
  }
}
