import {Component, OnInit} from '@angular/core';
import {Usuario} from '../../modules/core/models/usuario/usuario.model';
import {AuthService} from '../../modules/core/auth/auth.service';
import {AppService} from '../../modules/core/services/app.service';
import {Vinculo} from '../../modules/core/models/rhnet/vinculo.model';
import {VinculoVaga} from '../../modules/core/models/rhnet/vinculo-vaga.model';
import {AcessoUsuario} from '../../modules/core/models/usuario/acesso-usuario.mode';
import {Perfil} from '../../modules/core/models/usuario/perfil.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public userName: string = '';
  public givenName: string = '';
  public cpf: string = '';
  public email: string = '';
  public perfis: Perfil[] | undefined;
  public usuario: Usuario;
  public vinculo: Vinculo | undefined;
  public vinculoVagaAtuais: VinculoVaga[] | undefined;

  constructor(
    private authService: AuthService,
    private appService: AppService,) { }

  ngOnInit() {
    this.givenName = this.authService.getGivenName();
    this.cpf = this.authService.getCpf()
    this.email = this.authService.getEmail();
    this.userName = this.authService.getUsername();
    this.perfis = this.appService.getAcessosUsuario()?.perfis;
    this.usuario = this.appService.getUsuario();
    this.vinculo = this.appService.getVinculo();
    if (this.vinculo) {
      this.vinculoVagaAtuais = (<any>this.vinculo).vinculosVaga
          .filter((vaga: VinculoVaga) => vaga.statusAtivo === 'S');
    } else {
      this.vinculo = new Vinculo();
      this.vinculoVagaAtuais = [];
    }
  }

  emailCorporativo() {
    return (<any> this.authService).getEmailCorporativo();
  }
}
