<ng-template [ngIf]="property">
  <ng-template [ngIf]="page && !page.empty && page.orders.length > 0">
    <ng-template [ngIf]="page.orders[0].property == property">
      <ng-template [ngIf]="page.orders[0].descending">
        <th class="th-inner sortable both desc" (click)="changeOrder(property, directionAsc)" [ngClass]="class"
            scope="col">
          {{ title }}
        </th>
      </ng-template>
      <ng-template [ngIf]="page.orders[0].ascending">
        <th class="th-inner sortable both asc" (click)="changeOrder(property, directionDesc)" [ngClass]="class"
            scope="col">
          {{ title }}
        </th>
      </ng-template>
      <ng-template [ngIf]="!(page.orders[0].ascending || page.orders[0].descending)">
        <th class="th-inner sortable both" (click)="changeOrder(property, directionAsc)" [ngClass]="class"
            scope="col">
          {{ title }}
        </th>
      </ng-template>
    </ng-template>
    <ng-template [ngIf]="page.orders[0].property != property">
      <th class="th-inner sortable both" (click)="changeOrder(property, directionAsc)" [ngClass]="class"
          scope="col">
        {{ title }}
      </th>
    </ng-template>
  </ng-template>
  <ng-template [ngIf]="!(page && !page.empty && page.orders.length > 0)">
    <th class="th-inner sortable both" (click)="changeOrder(property, directionAsc)" [ngClass]="class"
        scope="col">
      {{ title }}
    </th>
  </ng-template>
</ng-template>
<ng-template [ngIf]="!property">
  <th class="th-inner" [ngClass]="class"
      scope="col">
    {{ title }}
  </th>
</ng-template>
