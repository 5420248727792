<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">
          Página não encontrada
        </h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a class="text-success">Home</a></li>
          <li class="breadcrumb-item active">Página não encontrada</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <div class="error-page">
    <h2 class="headline text-warning"> 404</h2>
    <div class="error-content">
      <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Página não encontrada.</h3>
      <p>
        Não foi possível encontrar a página que você está procurando.
        Enquanto isso, você pode <a href="/dashboard">retornar para o Dashboard</a>
      </p>
    </div>
  </div>
</section>
