import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AbstractCrudService } from "../abstract-crud.service";
import { ItemFichaFinanceira } from "../../models/tsv/ficha_financeira/item_ficha_financeira ";
import { PageRequest } from "../../models/page-sort/page-request.model";
import { Page } from "../../models/page-sort/page.model";

@Injectable({
  providedIn: "root",
})
export class ItemFichaFinanceiraService extends AbstractCrudService<ItemFichaFinanceira, number> {
  constructor(protected override http: HttpClient) {
    super(http, `${environment.apiUrl}`);
  }

  count(): Observable<number> {
    return this.http.get<number>(`${this.base}/item-ficha-financeira/all`);
  }

  findAllById(pageRequest?: PageRequest, id_fichaFinanceira?: number): Observable<Page<ItemFichaFinanceira>> {
    const params: { [key: string]: any } = !pageRequest
      ? {}
      : {
          page: pageRequest.page,
          size: pageRequest.size,
          sort: pageRequest.sort.column + "," + pageRequest.sort.direction,
        };

    return this.http.get<Page<ItemFichaFinanceira>>(`${this.base}/item-ficha-financeira/paginado/${id_fichaFinanceira}`, { params: params });
  }
}
