import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { IOrder } from "src/app/modules/core/interfaces/order.interface";
import { IPaginate } from "src/app/modules/core/interfaces/paginate.interface";
import { PageRequest } from "src/app/modules/core/models/page-sort/page-request.model";
import { Page } from "src/app/modules/core/models/page-sort/page.model";
import { SortDirection } from "src/app/modules/core/models/page-sort/sort-direction.enum";
import { Sort } from "src/app/modules/core/models/page-sort/sort.model";
import { FichaFinanceira } from "src/app/modules/core/models/tsv/ficha_financeira/ficha_financeira";
import { FichaFinanceiraService } from "src/app/modules/core/services/ficha-financeira/ficha-financeira.service";
import Swal from 'sweetalert2';
@Component({
  selector: "app-ficha-financeira-list",
  templateUrl: "./ficha-financeira-list.component.html",
  styleUrls: ["./ficha-financeira-list.component.scss"],
})
export class FichaFinanceiraListComponent implements OnInit, IPaginate, IOrder {
  public pageRequest: PageRequest;
  public sort: Sort;
  public page: Page<FichaFinanceira>;
  public _loading: boolean = false;
  public pg: string = "ficha-financeira";

  public searchResults: FichaFinanceira[];

  constructor(private router: Router, private toastr: ToastrService, private _fichaFinanceira: FichaFinanceiraService) {}

  ngOnInit(): void {
    this.limparEPesquisar();
  }

  getContent(): FichaFinanceira[] {
    if (this.page && this.page.content) {
      return this.page.content;
    } else {
      return [];
    }
  }

  limparEPesquisar() {
    this.sort = new Sort("id", SortDirection.ASCENDING);
    this.pageRequest = new PageRequest(0, 10, this.sort);
    this.pesquisar();
  }

  pesquisar() {
    this._loading = true;
    this._fichaFinanceira
      .page(this.pg, this.pageRequest)
      .subscribe(
        (response) => {
          this.page = response;
        },
        (error) => {
          this.toastr.error("Não foi possível listar os Fichas Financeiras.");
        }
      )
      .add(() => {
        this._loading = false;
      });
  }

  changeOrder(column: string, direction: SortDirection): void {
    this.pageRequest.sort = new Sort(column, direction);
    this.pesquisar();
  }

  changePageNumber(pageNumber: number): void {
    this.pageRequest.page = pageNumber - 1;
    this.pesquisar();
  }

  changeSize(size: number): void {
    this.pageRequest.page = 1;
    this.pageRequest.size = size;
    this.pesquisar();
  }

  addFicha(): void {
    this.router.navigate([`ficha-financeira/add`]);
  }

  editar(ficha: FichaFinanceira): void {
    this.router.navigate([`ficha-financeira/${ficha.id}/edit`]);
  }

  deletar(ficha: FichaFinanceira) {}

  remFichaFinac(index: any) {
    Swal.fire({
      title: 'Você tem certeza?',
      text: "Você vai deletar permanetemente!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, Deletar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this._fichaFinanceira.delete(index as number, this.pg)
          .subscribe({
            next: (response) => {
              console.log('AO Deletar', response);
            },
            error: (error) => {
              this.toastr.error(error.error.detail);
            },
            complete: () => {
              Swal.fire('Trabalhador Sem Vinculo', 'Ficha Financeira deletada com sucesso!', 'success');
            }

          })
      }
    })

  }
}
