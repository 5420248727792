import { Component, OnInit, LOCALE_ID, Input, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable, OperatorFunction, debounceTime, map } from "rxjs";
import { Sort } from "src/app/modules/core/models/page-sort/sort.model";
import { PageRequest } from "src/app/modules/core/models/page-sort/page-request.model";
import { SortDirection } from "src/app/modules/core/models/page-sort/sort-direction.enum";
import { Contrato } from "src/app/modules/core/models/tsv/contrato/contrato.model";
import { FichaFinanceira } from "src/app/modules/core/models/tsv/ficha_financeira/ficha_financeira";
import { Trabalhador } from "src/app/modules/core/models/tsv/trabalhador.model";
import { ContratoService } from "src/app/modules/core/services/contrato/contrato.service";
import { FichaFinanceiraService } from "src/app/modules/core/services/ficha-financeira/ficha-financeira.service";
import { TrabalhadorService } from "src/app/modules/core/services/trabalhador/trabalhador.service";
import { Page } from "src/app/modules/core/models/page-sort/page.model";
import Swal from "sweetalert2";
import { formatDate } from "@angular/common";
import { Uteis } from "src/app/modules/core/uteis/Uteis";

@Component({
  selector: "app-ficha-financeira-identification",
  templateUrl: "./ficha-financeira-identification.component.html",
  styleUrls: ["./ficha-financeira-identification.component.scss"],
})
export class FichaFinanceiraIdentificationComponent implements OnInit {
  @Input() fichaFinanceira: FichaFinanceira;
  @Input() formfichaFinanceira: FormGroup;

  public loading: boolean = false;
  public loaded: boolean = false;
  public pg: string = "ficha-financeira";
  trabalhadores: Trabalhador[];
  pageContratos: Page<Contrato>;
  id_: number;

  pageRequest: PageRequest;
  sort: Sort;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private service: FichaFinanceiraService,
    private activatedRoute: ActivatedRoute,
    private _router: Router,
    private _trabalhadorService: TrabalhadorService,
    private _contratoService: ContratoService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit(): void {
    this.fichaFinanceira = new FichaFinanceira();
    this.id_ = parseInt(String(this.activatedRoute.snapshot.paramMap.get("id")));
    this.setFichaFinanceira();

    this.getTrabalhadores();

    if (!this.id_) this.limparEPesquisar();
  }

  setFichaFinanceira() {
    if (this.id_) {
      this.loaded = false;
      this.loading = true;
      this.service.findOne(this.id_, this.pg).subscribe({
        next: (response) => {
          this.fichaFinanceira.id = response.id;
          this.fichaFinanceira.numeroAnoPagamento = response.numeroAnoPagamento;
          this.fichaFinanceira.numeroMesPagamento = response.numeroMesPagamento;
          this.fichaFinanceira.status = response.status;
          this.fichaFinanceira.contrato = response.contrato;
        },
        error: (error) => {
          this.toastr.error(error.error.message);
          this.loading = false;
        },
        complete: () => {
          this.createForm();
          this.loading = false;
          this.loaded = true;
        },
      });
    } else {
      this.newForm();
      this.loading = false;
      this.loaded = true;
    }
  }

  createForm(): void {
    (this.formfichaFinanceira as FormGroup).addControl(
      "fichaFinanceira",
      this.formBuilder.group({
        id: [this.fichaFinanceira.id],
        numeroAnoPagamento: [this.fichaFinanceira.numeroAnoPagamento],
        numeroMesPagamento: [this.fichaFinanceira.numeroMesPagamento],
        status: [this.parseAtivoOrInativoForBool(this.fichaFinanceira.status)],
        contrato: [this.fichaFinanceira.contrato],
        trabalhadorForm: [this.fichaFinanceira.contrato.trabalhador],
      })
    );
  }

  newForm(): void {
    (this.formfichaFinanceira as FormGroup).addControl(
      "fichaFinanceira",
      this.formBuilder.group({
        numeroAnoPagamento: "",
        numeroMesPagamento: "",
        status: "",
        contrato: [new Contrato()],
        trabalhadorForm: [""],
      })
    );
  }

  getData(): Object {
    return {
      id: parseInt(String(this.formfichaFinanceira.controls.fichaFinanceira.value.id)),
      numeroAnoPagamento: parseInt(this.formfichaFinanceira.controls.fichaFinanceira.value.numeroAnoPagamento),
      numeroMesPagamento: parseInt(this.formfichaFinanceira.controls.fichaFinanceira.value.numeroMesPagamento),
      status: this.parseBoolForAtivoOrInativo(this.formfichaFinanceira.controls.fichaFinanceira.value.status),
      contrato: this.getDataContrato() as Contrato,
    };
  }

  getDataContrato(): Object {
    return {
      id: parseInt(String(this.formfichaFinanceira.controls.fichaFinanceira.value.contrato.id)),
    };
  }

  parseBoolForSimOrNao(bool: Boolean) {
    if (bool == false) {
      return "N";
    } else if (bool == true) {
      return "S";
    } else {
      return "N";
    }
  }

  parseBoolForAtivoOrInativo(bool: Boolean) {
    if (bool == false) {
      return "I";
    } else if (bool == true) {
      return "A";
    } else {
      return "I";
    }
  }
  arseBoolForSimOrNao(bool: Boolean) {
    if (bool == false) {
      return "N";
    } else if (bool == true) {
      return "S";
    } else {
      return "N";
    }
  }
  parseAtivoOrInativoForBool(AtivoOrInativo: string) {
    if (AtivoOrInativo == "I") {
      return false;
    } else if (AtivoOrInativo == "A") {
      return true;
    } else {
      return false;
    }
  }

  parseSimOrNaoForBool(SimOrNao: string) {
    if (SimOrNao == "N") {
      return false;
    } else if (SimOrNao == "S") {
      return true;
    } else {
      return false;
    }
  }

  transformDate(date: Date) {
    return formatDate(date, "yyyy-MM-dd", this.locale, 'GMT+8');
  }

  getTrabalhadores() {
    this.loading = true;
    this._trabalhadorService.findAll("trabalhador").subscribe({
      next: (response: any): any => {
        this.trabalhadores = response;

        if (response.length <= 0) {
          Swal.fire(
            "Trabalhador Sem Vinculo",
            "Para criar um ficha financeira, você deve ter trabalhadores ativos no sistema!",
            "warning").then(() =>
              this._router.navigate([`ficha-financeira`])
            );
        }
      },
      error: (error) => {
        this.toastr.error(error.error.message);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  getContratos() {
    let trabalhador = this.formfichaFinanceira.controls.fichaFinanceira.value.trabalhadorForm;
    this.loading = true;
    console.log(trabalhador);

    this._contratoService
      .findAllById(this.pageRequest, trabalhador.id)
      .subscribe(
        (response) => {
          this.pageContratos = response;
          if (response.content.length <= 0) {
            Swal.fire(
              "Trabalhador Sem Vinculo",
              "Trabalhador informado não possue contrato cadastrado!",
              "warning");
          }
        },
        (error) => {
          this.toastr.error("Não foi possível listar os contratos do Trabalhador.");
        }
      )
      .add(() => {
        this.loading = false;
      });
  }

  public validarCampos(): boolean {
    let retorno: boolean = true;
    if (
      !Uteis.trataVar(this.formfichaFinanceira.controls.fichaFinanceira.value.numeroAnoPagamento, false) ||
      !Uteis.trataVar(this.formfichaFinanceira.controls.fichaFinanceira.value.numeroMesPagamento, false) ||
      !Uteis.trataVar(this.formfichaFinanceira.controls.fichaFinanceira.value.status, false) ||
      !Uteis.trataVar(this.formfichaFinanceira.controls.fichaFinanceira.value.contrato.id, false)
    ) {
      retorno = false;
    }
    return retorno;
  }

  salvarFicha() {
    if (!this.validarCampos()) {
      Swal.fire("Trabalhador Sem Vinculo", "Um ou mais campos obrigatórios não foram preenchidos!", "warning");
      return;
    }
    let values = this.getData() as FichaFinanceira;

    this.loading = true;
    if (this.id_) {
      this.service.update(values.id, values, this.pg).subscribe({
        next: (response) => {
          console.log("AO GRAVAR", response);
        },
        error: (error) => {
          this.toastr.error(error.error.detail);
          this.loading = false;
        },
        complete: () => {
          this.limparEPesquisar();
          this.loading = false;
          Swal.fire("Trabalhador Sem Vinculo", "Ficha Financeira Alterada com sucesso!", "success");
        },
      });
    } else {
      this.service.save(values, this.pg).subscribe({
        next: (response) => {
          console.log("AO GRAVAR", response);
        },
        error: (error) => {
          console.log("AO error", error);
          this.toastr.error(error.error.detail);
          this.loading = false;
        },
        complete: () => {
          this.limparEPesquisar();
          this.loading = false;
          Swal.fire("Trabalhador Sem Vinculo", "Ficha Financeira inserida com sucesso!", "success");
        },
      });
    }
    this.loading = false;
  }

  limparEPesquisar() {
    this.sort = new Sort("id", SortDirection.ASCENDING);
    this.pageRequest = new PageRequest(0, 10, this.sort);
    this.formfichaFinanceira?.get("fichaFinanceira")?.reset();
  }

  searchTrabalhadores: OperatorFunction<string, readonly { id?: number; nome: string }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map((term) => (term === "" ? [] : this.trabalhadores.filter((t) => t.nome.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))
    );

  formatterTrabalhadores = (trabalhadores: { id: number; nome: string }) => {
    return `${trabalhadores.nome}`;
  };

  searchContratos: OperatorFunction<string, readonly { id?: number; observacao?: string }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map((term) => (term === "" ? [] : this.pageContratos.content.filter((t) => t.observacao!.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))
    );

  formatterContratos = (contratos: { id: number; observacao: string }) => {
    return `${contratos.id} - ${contratos.observacao} `;
  };
}
