import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {PortalService} from './portal/portal.service';
import {AuthService} from '../auth/auth.service';
import {Usuario} from '../models/usuario/usuario.model';
import {AcessosUsuarioService} from './acessos-usuario/acessos-usuario.service';
import {SessionStorageService} from './session-storage/session-storage.service';
import {MenuService} from './menu/menu.service';
import {Vinculo} from '../models/rhnet/vinculo.model';
import {RhNetService} from './rhnet/rh.service';
import {lastValueFrom} from 'rxjs';
import {AcessoUsuario} from '../models/usuario/acesso-usuario.mode';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private _acessosUsuario: AcessoUsuario | undefined;
  private _vinculo: Vinculo | undefined;

  constructor(private portalService: PortalService,
              private rhnetService: RhNetService,
              private router: Router,
              private authService: AuthService,
              private sessionStorageService: SessionStorageService,
              private acessosUsuarioService: AcessosUsuarioService,
              private menuService: MenuService,
              private toastr: ToastrService) { }

  async loadAccessData(): Promise<void> {
    try {
      if (this.authService.isValidToken()) {

        await this.acessosUsuarioService.load();

        if (this.acessosUsuarioService.isNotEmptyRoles()) {
          await this.loadAppData();
          this.router.initialNavigation();
        } else {
          this.router.navigate(['/403']);
        }

      } else {
        this.sessionStorageService.clear();
        this.router.initialNavigation();
      }
    } catch (error) {
      console.log(error);
      this.toastr.error('Erro interno ao buscar dados da aplicação');
    }
  }

  async loadAppData(): Promise<void> {
    try {
      if (this.authService.isValidToken()) {
        this._acessosUsuario = await this.findAcessosUsuario();
        //this._vinculo = await this.findVinculoUsuario();
        this.sessionStorageService.set('app_acessosUsuario', this._acessosUsuario);
        //this.menuService.loadMenus();
      }
    } catch (error) {
      console.log(error);
      this.toastr.error('Erro interno ao buscar dados da aplicação');
    }
  }

  async findAcessosUsuario(): Promise<AcessoUsuario | undefined> {
    if (!this.sessionStorageService.isExist('app_acessos')) {
      return this.portalService.findAcessosUsuario().toPromise();
    } else {
      return this.sessionStorageService.get('app_acessos');
    }
  }

  async findVinculoUsuario(): Promise<Vinculo | undefined> {
    if (!this.sessionStorageService.isExist('app_vinculo')) {
      let vinculo = await lastValueFrom(this.rhnetService.findViculoByCpf())
          .catch(err => {
            this.toastr.error(err?.detail ?? err?.message)
          });
      if (vinculo) {
        return <any>vinculo;
      } else {
        return new Vinculo();
      }
    } else {
      return this.sessionStorageService.get('app_vinculo');
    }
  }

  getVinculo(): Vinculo {
    return (<any>this._vinculo)[0];
  }

  getUsuario(): Usuario {
    return this?._acessosUsuario ? this?._acessosUsuario : (<any>[0])?.usuario;
  }

  getAcessosUsuario(): AcessoUsuario | undefined {
    return this._acessosUsuario;
  }

  isAppDataOk(): boolean {
    // @ts-ignore
    return this._acessosUsuario['perfis'].length > 0;
  }
}
