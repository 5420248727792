export enum MensagensEnum {
  VALIDACAO_CAMPOS_OBRIGATORIOS = "Ops!  Não foram preenchidos campos obrigatórios, preencha os campos com asterísco para continuar.",
  VALIDACAO_DADOS = "Ops! Você não digitou um dado válido.",
  CONFIRMACAO = "Tem certeza desta ação? Ela não poderá ser desfeita.",
  REMOVER_CONFIRMACAO = "Tem certeza de que deseja apagar os itens selecionados?",
  REMOVER_VALIDACAO_VINCULOS = "Ops! Não foi possível excluir o registro, existem outras informações vinculadas a esse cadastro.",
  REMOVER_SUCESSO = "Sucesso! Os dados foram excluídos",
  REMOVER_ANEXO_SUCESSO = "Sucesso! O anexo foi excluído",
  CADASTRO_SUCESSO = "Sucesso! Os dados foram incluídos.",
  ALTERACAO_SUCESSO = "Sucesso! Os dados foram atualizados.",
  INSTABILIDADE = "Estamos passando por instabilidades, tente novamente em alguns instantes.",
  FALHA_REDE = "Falha de conexão com o servidor, tente novamente em alguns instantes.",
  ERRO_401 = "Seu usuário perdeu a sessão ou foi desconectado. Por favor logue novamente.",
  ERRO_403 = "Seu usuário não possui permissão para acessar este conteúdo.",
  ERRO_400 = "Há algum dado de formulário inválido enviado ao servidor.",
}
