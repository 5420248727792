import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Rubricas } from "../../models/tsv/rubricas/rubricas.model";
import { environment } from "src/environments/environment";
import { AuthService } from "../../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class RubricasService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  findAll() {
    return this.http.get<Rubricas>(`${environment.apiUrl}/rubricas/`);
  }
}
