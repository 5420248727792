import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MainComponent } from "./modules/main/main.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ProfileComponent } from "./pages/profile/profile.component";

import { TrabalhadorListComponent } from "./pages/trabalhador/trabalhador-list/trabalhador-list.component";
import { TrabalhadorFormComponent } from "./pages/trabalhador/trabalhador-form/trabalhador-form.component";

import { AuthGuard } from "./modules/core/auth/auth.guard.service";
import { P404Component } from "./pages/error/404.component";
import { NonAuthGuard } from "./modules/core/auth/non-auth.guard";
import { P500Component } from "./pages/error/500.component";
import { P403Component } from "./pages/error/403.component";
import { FichaFinanceiraListComponent } from "./pages/ficha-financeira/ficha-financeira-list/ficha-financeira-list.component";
import { FichaFinanceiraFormsComponent } from "./pages/ficha-financeira/ficha-financeira-form/ficha-financeira-form.component";

const routes: Routes = [
  {
    path: "",
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "trabalhador",
        component: TrabalhadorListComponent,
      },
      {
        path: "trabalhador/add",
        component: TrabalhadorFormComponent,
      },
      {
        path: "trabalhador/:id/edit",
        component: TrabalhadorFormComponent,
      },
      {
        path: "ficha-financeira",
        component: FichaFinanceiraListComponent,
      },
      {
        path: "ficha-financeira/add",
        component: FichaFinanceiraFormsComponent,
      },
      {
        path: "ficha-financeira/:id/edit",
        component: FichaFinanceiraFormsComponent,
      },
      {
        path: "profile",
        component: ProfileComponent,
      },
      {
        path: "",
        component: DashboardComponent,
        canActivate: [NonAuthGuard],
      },
    ],
  },
  { path: "403", component: P403Component, canActivate: [NonAuthGuard] },
  { path: "500", component: P500Component, canActivate: [NonAuthGuard] },
  { path: "**", component: P404Component, canActivate: [NonAuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "disabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
