import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { PageRequest } from "../../models/page-sort/page-request.model";
import { Page } from "../../models/page-sort/page.model";
import { Endereco } from "../../models/tsv/endereco.model";
import { AbstractCrudService } from "../abstract-crud.service";

@Injectable({
  providedIn: 'root'
})

export class EnderecoService extends AbstractCrudService< Endereco, number> {
  constructor(protected override http: HttpClient) {
    super(http, `${environment.apiUrl}`);
  }

  count(): Observable<number> {
    return this.http.get<number>(`${this.base}/endereco/all`);
  }

 

  findAllById( pageRequest?: PageRequest, id_trabalhador?: number ): Observable<Page<Endereco>> {
    const params: { [key: string]: any } = !pageRequest ? {} : {
      page: pageRequest.page,
      size: pageRequest.size,
      sort: pageRequest.sort.column + ',' + pageRequest.sort.direction
    };

    return this.http.get<Page<Endereco>>(`${this.base}/endereco/paginado/${id_trabalhador}`, { params: params })
  }

  getEnderecoCdn(cep: string) {
    return this.http.get(`https://cdn.apicep.com/file/apicep/${cep}.json`)
  }

  getEnderecoVia(cep: string) {
    return this.http.get(`https://viacep.com.br/ws/${cep}/json`)
  }

}