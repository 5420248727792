import { Injectable } from "@angular/core";
import { MensagensEnum } from "src/app/enums/mensagens.enum";
import Swal, { SweetAlertIcon } from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class MensagensService {
  constructor() {}

  public exibirValidacaoCamposObrigatorios() {
    this.exibirAlerta(MensagensEnum.VALIDACAO_CAMPOS_OBRIGATORIOS);
  }

  public exibirValidacaoDados() {
    this.exibirAlerta(MensagensEnum.VALIDACAO_DADOS);
  }

  public exibirRemoverConfirmacao() {
    this.exibirAlerta(MensagensEnum.REMOVER_CONFIRMACAO);
  }

  public exibirRemoverValidacaoVinculos() {
    this.exibirAlerta(MensagensEnum.REMOVER_VALIDACAO_VINCULOS);
  }

  public exibirRemoverSucesso() {
    this.exibirSucesso(MensagensEnum.REMOVER_SUCESSO);
  }

  public exibirRemoverAnexoSucesso() {
    this.exibirSucesso(MensagensEnum.REMOVER_ANEXO_SUCESSO);
  }

  public exibirCadastroSucesso() {
    this.exibirSucesso(MensagensEnum.CADASTRO_SUCESSO);
  }

  public exibirAlteracaoSucesso() {
    this.exibirSucesso(MensagensEnum.ALTERACAO_SUCESSO);
  }

  public exibirInstabilidade() {
    this.exibirErro(MensagensEnum.INSTABILIDADE);
  }

  public exibirSucesso(mensagem: string) {
    this.exibirMensagem("Sucesso", "success", mensagem);
  }

  public exibirAlerta(mensagem: string) {
    this.exibirMensagem("Atenção", "warning", mensagem);
  }

  public exibirErro(mensagem: string) {
    this.exibirMensagem("Erro", "error", mensagem);
  }

  public exibirInfo(mensagem: string) {
    this.exibirMensagem("Informação", "info", mensagem);
  }

  private exibirMensagem(sumario: string, tipo: string, mensagem: string) {
    Swal.fire({
      title: "Opss...",
      text: "Não foram preenchidos campos obrigatórios, preencha os campos com asterisco para continuar!",
      icon: tipo as SweetAlertIcon,
    });
  }
}
