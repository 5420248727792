import { formatDate } from "@angular/common";
import { Component, Inject, Input, LOCALE_ID, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { PageRequest } from "src/app/modules/core/models/page-sort/page-request.model";
import { Page } from "src/app/modules/core/models/page-sort/page.model";
import { SortDirection } from "src/app/modules/core/models/page-sort/sort-direction.enum";
import { Sort } from "src/app/modules/core/models/page-sort/sort.model";
import { Contrato } from "src/app/modules/core/models/tsv/contrato/contrato.model";
import { Trabalhador } from "src/app/modules/core/models/tsv/trabalhador.model";
import { ContratoService } from "src/app/modules/core/services/contrato/contrato.service";
import { indiNivelInstrucaoEstagio } from "../../../../assets/data";

import Swal from "sweetalert2";
import { RubricasService } from "src/app/modules/core/services/rubricas/rubricas.service";
import { Rubricas } from "src/app/modules/core/models/tsv/rubricas/rubricas.model";
import { Observable, OperatorFunction, debounceTime, map } from "rxjs";
import { ItemContrato } from "src/app/modules/core/models/tsv/contrato/itemContrato.model";
import { ItemContratoService } from "src/app/modules/core/services/item-contrato/item-contrato.service";

@Component({
  selector: "app-trabalhador-contrato",
  templateUrl: "./trabalhador-contrato.component.html",
  styleUrls: ["./trabalhador-contrato.component.scss"],
})
export class TrabalhadorContratoComponent implements OnInit {
  @Input()
  trabalhador: Trabalhador;

  @Input()
  formTrabalhador: FormGroup;

  @Input()
  isDisabled: boolean;

  loading: boolean = false;
  loadingContrato: boolean = false;
  editing: boolean = false;
  andNew: boolean = false;
  contrato: Contrato;
  contratos: Contrato[];
  pg: string = "contrato";
  pgItemContrato: string = "item-contrato";
  page: Page<Contrato>;
  pageRequest: PageRequest;
  sort: Sort;
  id_: number;
  rubrica: Rubricas;
  rubricas: Rubricas[];
  itemContrato: ItemContrato;
  itemsContrato: ItemContrato[] = [];

  public data = {
    indiNivelInstrucaoEstagio,
  };

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private _contratoService: ContratoService,
    private _rubricasService: RubricasService,
    private _itemContratoService: ItemContratoService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getRubricas();
    this.formTrabalhador?.get("itemContrato")?.reset();
  }

  setContrato() {
    this.contrato = new Contrato();
  }

  createForm(): void {
    this.limparEPesquisar();
    if (!this.formTrabalhador?.get("contratos")) {
      this.newForm();
      this.setContrato();
    }
  }

  newForm(): void {
    console.log("newForm");
    (this.formTrabalhador as FormGroup).addControl(
      "contratos",
      this.formBuilder.group({
        id: [this.contrato?.id],
        codigoDecisaoJudicial: [this.contrato?.codigoDecisaoJudicial],
        codigoOrgao: [this.contrato?.codigoOrgao],
        codigoUnidadeAdm: [this.contrato?.codigoUnidadeAdm],
        observacao: [this.contrato?.observacao],
        dataInicioContrato: [this.parseDate("")],
        dataFimContrato: [this.parseDate("")],
        indiEstagio: [this.contrato?.indiEstagio],
        numeroCnpjEstagio: [this.contrato?.numeroCnpjEstagio],
        indiNaturezaEstagio: [this.contrato?.indiNaturezaEstagio],
        indiNivelInstrucaoEstagio: [this.contrato?.indiNivelInstrucaoEstagio],
        numeroApoliceSeguro: [this.contrato?.numeroApoliceSeguro],
        numeroCpfSupervisorEstagio: [this.contrato?.numeroCpfSupervisorEstagio],
        infoEstagioAreaAtuacao: [this.contrato?.infoEstagioAreaAtuacao],
        codigoCboTrabalhador: [this.contrato?.codigoCboTrabalhador],
        trabalhador: [this.contrato?.trabalhador],
        codigoCategoriaTabalhador: [this.contrato?.codigoCategoriaTabalhador],
        codigoBancoTrabalhador: [this.contrato?.codigoBancoTrabalhador],
        codigoAgenciaTrabalhador: [this.contrato?.codigoAgenciaTrabalhador],
        nomeCargo: [this.contrato?.nomeCargo],
        statusContrato: [this.contrato?.statusContrato],
        itemsContrato: [this.contrato?.itemsContrato],
      })
    );
    (this.formTrabalhador as FormGroup).addControl(
      "itemContrato",
      this.formBuilder.group({
        id: [this.itemContrato?.id],
        valorItemContrato: [this.itemContrato?.valorItemContrato],
        qtdParcelaContrato: [this.itemContrato?.qtdparcelacontrato],
        descricaoObservacao: [this.itemContrato?.descricaoObservacao],
        descricaoItemContrato: [this.itemContrato?.descricaoItemContrato],
        codigoRubrica: [this.itemContrato?.codigoRubrica],
        tipoItemContrato: [this.itemContrato?.tipoItemContrato],
        rubrica: [""],
      })
    );
  }

  limparEPesquisar() {
    this.sort = new Sort("id", SortDirection.ASCENDING);
    this.pageRequest = new PageRequest(0, 10, this.sort);
    this.pesquisar();
  }

  newContrato() {
    this.formTrabalhador.get("contratos")?.reset();
    this.editing = true;
    this.andNew = true;
  }

  pesquisar() {
    this.id_ = parseInt(String(this.activatedRoute.snapshot.paramMap.get("id")));
    let value = this.getDataTrbalhador();
    this.loadingContrato = true;
    if (value !== null) {
      this._contratoService
        .findAllById(this.pageRequest, this.id_)
        .subscribe(
          (response) => {
            this.page = response;
          },
          (error) => {
            this.toastr.error("Não foi possível listar os contratos do Trabalhador.");
          }
        )
        .add(() => {
          this.loadingContrato = false;
        });
    }
  }

  getContent(): Contrato[] {
    if (this.page && this.page.content) {
      return this.page.content;
    } else {
      return [];
    }
  }

  getDataTrbalhador(): Object {
    let trabalhador = this.formTrabalhador?.get("trabalhador");

    return {
      id: parseInt(String(this.activatedRoute.snapshot.paramMap.get("id"))),
      cpf: parseInt(trabalhador?.get("cpf")?.value.replace(/[^a-z0-9]/gi, "")),
      nome: String(trabalhador?.get("nome")?.value),
      sexo: trabalhador?.get("sexo")?.value,
      racaCor: trabalhador?.get("racaCor")?.value,
      estadoCivil: trabalhador?.get("estadoCivil")?.value,
      tpGrauInstr: parseInt(trabalhador?.get("grauInstrucao")?.value),
      nomeSocial: String(trabalhador?.get("nomeSocial")?.value),
      dataNascimento: this.transformDate(trabalhador?.get("dataNascimento")?.value),

      resideExterior: this.parseBoolForSimOrNao(trabalhador?.get("resideExterior")?.value),
      imigrante: this.parseBoolForSimOrNao(trabalhador?.get("imigrante")?.value),
      possuiDeficiencia: this.parseBoolForSimOrNao(trabalhador?.get("possuiDeficiencia")?.value),
      possuiDependentes: this.parseBoolForSimOrNao(trabalhador?.get("possuiDependentes")?.value),
      paisNascimento: parseInt(String(trabalhador?.get("paisNascimento")?.value.id)),
      paisNacionalidade: parseInt(String(trabalhador?.get("paisNacionalidade")?.value.id)),
      deficienteFisico: this.parseBoolForSimOrNao(trabalhador?.get("deficienteFisico")?.value),
      deficienteVisual: this.parseBoolForSimOrNao(trabalhador?.get("deficienteVisual")?.value),
      deficienteAuditivo: this.parseBoolForSimOrNao(trabalhador?.get("deficienteAuditivo")?.value),
      deficienteMental: this.parseBoolForSimOrNao(trabalhador?.get("deficienteMental")?.value),
      deficienteIntelectual: this.parseBoolForSimOrNao(trabalhador?.get("deficienteIntelectual")?.value),

      tempoResidencia: parseInt(trabalhador?.get("tempoResidencia")?.value),
      condicaoIngresso: parseInt(trabalhador?.get("estadocondicaoIngressoCivil")?.value),
    };
  }

  getDataItemsContrato() {
    return this.itemsContrato;
  }

  transformDate(date: Date) {
    return formatDate(date, "yyyy-MM-dd", this.locale, 'GMT+8');
  }

  parseBoolForSimOrNao(bool: Boolean) {
    if (bool == false) {
      return "N";
    } else if (bool == true) {
      return "S";
    } else {
      return "N";
    }
  }
  parseBoolForAtivoOrInativo(bool: Boolean) {
    if (bool == false) {
      return "I";
    } else if (bool == true) {
      return "A";
    } else {
      return "I";
    }
  }

  parseAtivoOrInativoForBool(AtivoOrInativo: string) {
    if (AtivoOrInativo == "I") {
      return false;
    } else if (AtivoOrInativo == "A") {
      return true;
    } else {
      return false;
    }
  }

  parseSimOrNaoForBool(SimOrNao: string) {
    if (SimOrNao == "N") {
      return false;
    } else if (SimOrNao == "S") {
      return true;
    } else {
      return false;
    }
  }

  private parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return new Date();
  }

  setCpf(cpf: string) {
    cpf = String(cpf);

    if (cpf.length < 11) {
      for (let i = cpf.length; i < 11; i++) {
        cpf = `0${cpf}`;
      }
    }

    return cpf;
  }

  getData(): Object {
    return {
      id: parseInt(String(this.formTrabalhador.controls.contratos.value.id)),
      codigoDecisaoJudicial: parseInt(this.formTrabalhador.controls.contratos.value.codigoDecisaoJudicial),
      codigoOrgao: parseInt(this.formTrabalhador.controls.contratos.value.codigoOrgao),
      codigoUnidadeAdm: parseInt(this.formTrabalhador.controls.contratos.value.codigoUnidadeAdm),
      observacao: String(this.formTrabalhador.controls.contratos.value.observacao),
      dataInicioContrato: this.transformDate(this.formTrabalhador.controls.contratos.value.dataInicioContrato),
      dataFimContrato: this.transformDate(this.formTrabalhador.controls.contratos.value.dataFimContrato),
      indiEstagio: this.parseBoolForSimOrNao(this.formTrabalhador.controls.contratos.value.indiEstagio),
      numeroCnpjEstagio: parseInt(String(this.formTrabalhador.controls.contratos.value.numeroCnpjEstagio.replace(/[^a-z0-9]/gi, ""))),
      indiNaturezaEstagio: this.parseBoolForSimOrNao(this.formTrabalhador.controls.contratos.value.indiNaturezaEstagio),
      indiNivelInstrucaoEstagio: parseInt(this.formTrabalhador.controls.contratos.value.indiNivelInstrucaoEstagio),
      numeroApoliceSeguro: parseInt(this.formTrabalhador.controls.contratos.value.numeroApoliceSeguro),
      numeroCpfSupervisorEstagio: parseInt(String(this.formTrabalhador.controls.contratos.value.numeroCpfSupervisorEstagio.replace(/[^a-z0-9]/gi, ""))),
      infoEstagioAreaAtuacao: String(this.formTrabalhador.controls.contratos.value.infoEstagioAreaAtuacao),
      codigoCboTrabalhador: parseInt(this.formTrabalhador.controls.contratos.value.codigoCboTrabalhador),
      trabalhador: this.getDataTrbalhador() as Trabalhador,
      codigoCategoriaTabalhador: parseInt(this.formTrabalhador.controls.contratos.value.codigoCategoriaTabalhador),
      codigoBancoTrabalhador: parseInt(this.formTrabalhador.controls.contratos.value.codigoBancoTrabalhador),
      codigoAgenciaTrabalhador: parseInt(this.formTrabalhador.controls.contratos.value.codigoAgenciaTrabalhador),
      nomeCargo: String(this.formTrabalhador.controls.contratos.value.nomeCargo),
      statusContrato: this.parseBoolForAtivoOrInativo(this.formTrabalhador.controls.contratos.value.statusContrato),
    };
  }

  addContrato(): void {
    this.editing = false;
    let values = this.getData();
    this.contrato = new Contrato();

    this.loading = true;
    this.id_ = parseInt(String(this.activatedRoute.snapshot.paramMap.get("id")));

    if (this.id_ > 0) {
      console.log(this.id_);
      if (this.andNew) {
        this._contratoService.save(values as Contrato, this.pg).subscribe({
          next: (response) => {
            console.log("AO GRAVAR", response);
            this.cancelEdit();
          },
          error: (error) => {
            this.toastr.error(error.error.message);
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
            Swal.fire("Trabalhador Sem Vinculo", "Contrato Inserido com sucesso!", "success");
          },
        });
      } else {
        this.contrato.id = parseInt(String(this.formTrabalhador.controls.contratos.value.id)) as number;
        this._contratoService.update(this.contrato.id as number, values as Contrato, this.pg).subscribe({
          next: (response) => {
            console.log("AO GRAVAR", response);
            this.cancelEdit();
          },
          error: (error) => {
            this.toastr.error(error.error.message);
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
            Swal.fire("Trabalhador Sem Vinculo", "Contrato Alterado com sucesso!", "success");
          },
        });
      }
    } else {
      Swal.fire("Trabalhador Sem Vinculo", "Salve primeiro os dados principais", "warning");
      this.loading = false;
    }

    this.cancelEdit();
    this.loading = false;
  }

  editContrato(contrato: Contrato) {
    let contratos = this.formTrabalhador.get("contratos");
    contratos?.get("id")?.setValue(contrato.id);
    contratos?.get("codigoDecisaoJudicial")?.setValue(contrato.codigoDecisaoJudicial);
    contratos?.get("codigoOrgao")?.setValue(contrato.codigoOrgao);
    contratos?.get("codigoUnidadeAdm")?.setValue(contrato.codigoUnidadeAdm);
    contratos?.get("observacao")?.setValue(contrato.observacao);
    contratos?.get("dataInicioContrato")?.setValue(this.transformDate(contrato.dataInicioContrato));
    contratos?.get("dataFimContrato")?.setValue(this.transformDate(contrato.dataFimContrato));
    contratos?.get("indiEstagio")?.setValue(this.parseSimOrNaoForBool(contrato.indiEstagio));
    contratos?.get("numeroCnpjEstagio")?.setValue(this.setCpf(String(contrato.numeroCnpjEstagio)));
    contratos?.get("indiNaturezaEstagio")?.setValue(this.parseSimOrNaoForBool(contrato.indiNaturezaEstagio));
    contratos?.get("indiNivelInstrucaoEstagio")?.setValue(contrato?.indiNivelInstrucaoEstagio);
    contratos?.get("numeroApoliceSeguro")?.setValue(contrato?.numeroApoliceSeguro);
    contratos?.get("numeroCpfSupervisorEstagio")?.setValue(this.setCpf(String(contrato?.numeroCpfSupervisorEstagio)));
    contratos?.get("codigoCboTrabalhador")?.setValue(contrato?.codigoCboTrabalhador);
    contratos?.get("infoEstagioAreaAtuacao")?.setValue(contrato?.infoEstagioAreaAtuacao);
    contratos?.get("trabalhador")?.setValue(contrato?.trabalhador);
    contratos?.get("codigoCategoriaTabalhador")?.setValue(contrato?.codigoCategoriaTabalhador);
    contratos?.get("codigoBancoTrabalhador")?.setValue(contrato?.codigoBancoTrabalhador);
    contratos?.get("codigoAgenciaTrabalhador")?.setValue(contrato?.codigoAgenciaTrabalhador);
    contratos?.get("nomeCargo")?.setValue(contrato?.nomeCargo);
    contratos?.get("statusContrato")?.setValue(this.parseAtivoOrInativoForBool(contrato?.statusContrato));

    this.editing = true;
    this.andNew = false;
    this.getItemsContrato(contrato.id!);
  }

  removerContrato(index: any) {
    Swal.fire({
      title: "Tem certeza desta ação?",
      text: "Ela não poderá ser desfeita.!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Deletar!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;

        this._contratoService.delete(index as number, this.pg).subscribe({
          next: (response) => {
            console.log("AO Deletar", response);
            this.cancelEdit();
          },
          error: (error) => {
            this.toastr.error(error.error.message);
            this.loading = false;
          },
          complete: () => {
            Swal.fire("Trabalhador Sem Vinculo", "Contrato deletado com sucesso!", "success");
            this.loading = false;
          },
        });
      }
    });
  }

  getItemContratoData(): Object {
    return {
      id: parseInt(String(this.formTrabalhador.controls.itemContrato.value.id)),
      valorItemContrato: parseInt(this.formTrabalhador.controls.itemContrato.value.valorItemContrato),
      qtdparcelacontrato: parseInt(String(this.formTrabalhador.controls.itemContrato.value.qtdParcelaContrato)),
      descricaoObservacao: String(this.formTrabalhador.controls.itemContrato.value.descricaoObservacao),
      descricaoItemContrato: String(this.formTrabalhador.controls.itemContrato.value.rubrica.nome),
      tipoItemContrato: String(this.formTrabalhador.controls.itemContrato.value.rubrica.tipoRubrica),
      codigoRubrica: parseInt(String(this.formTrabalhador.controls.itemContrato.value.rubrica.id)),
      contrato: this.getData() as Contrato,
    };
  }

  AddRubrica() {
    let itemContrato = this.getItemContratoData() as ItemContrato;
    this._itemContratoService.save(itemContrato, this.pgItemContrato).subscribe({
      next: (response) => {
        console.log("AO GRAVAR", response);
        this.getItemsContrato(itemContrato.contrato.id!);
      },
      error: (error) => {
        this.toastr.error(error.error.message);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
        Swal.fire("Trabalhador Sem Vinculo", "Novo item adicionado com sucesso!", "success");
      },
    });
  }

  ExcluirRubrica(itemContrato: ItemContrato) {
    Swal.fire({
      title: "Tem certeza desta ação?",
      text: "Ela não poderá ser desfeita.!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Deletar!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;

        this._itemContratoService.delete(itemContrato.id!, this.pgItemContrato).subscribe({
          next: (response: any): any => {
            this.getItemsContrato(itemContrato.contrato.id!);
          },
          error: (error) => {
            this.toastr.error(error.error.message);
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
            Swal.fire("Trabalhador Sem Vinculo", "Item do contrato exluido com sucesso!", "success");
          },
        });
      }
    });
  }

  cancelEdit() {
    this.editing = false;
    this.formTrabalhador.get("contratos")?.reset();
    this.limparEPesquisar();
  }

  searchRubricas: OperatorFunction<string, readonly { id: number; nome: string }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map((term) => (term === "" ? [] : this.rubricas.filter((rubrica) => rubrica.nome.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))
    );

  formatterRubricas = (rubrica: { id: number; nome: string }) => {
    return `${rubrica.id} - ${rubrica.nome}`;
  };

  getRubricas() {
    this.loading = true;
    this._rubricasService.findAll().subscribe({
      next: (response: any): any => {
        this.rubricas = response;
        console.log(response);
      },
      error: (error) => {
        this.toastr.error(error.error.message);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  getItemsContrato(id: number) {
    this.loading = true;
    this._itemContratoService.findAllById(this.pageRequest, id).subscribe({
      next: (response: any): any => {
        this.itemsContrato = response.content;
      },
      error: (error) => {
        this.toastr.error(error.error.message);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  getItensContrato(): ItemContrato[] {
    return this.itemsContrato;
  }
}
