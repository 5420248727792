<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Trabalhador</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a class="text-success">Home</a></li>
          <li class="breadcrumb-item active">Trabalhador</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

  <form [formGroup]="trabalhadorForm" (ngSubmit)="onSubmit()">
    <div>
      <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="tabActive">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Identificação</a>
          <ng-template ngbNavContent class="px-4 py-4">
            <app-trabalhador-identification [formTrabalhador]="trabalhadorForm"></app-trabalhador-identification>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" [disabled]="isDisabled">
          <a ngbNavLink>Endereço</a>
          <ng-template ngbNavContent>
            <app-trabalhador-endereco [formTrabalhador]="trabalhadorForm"
              [isDisabled]="isDisabled"></app-trabalhador-endereco>
          </ng-template>
        </li>
        <li [ngbNavItem]="3" [disabled]="isDisabled">
          <a ngbNavLink>Dependentes</a>
          <ng-template ngbNavContent>
            <app-trabalhador-dependente [formTrabalhador]="trabalhadorForm"
              [isDisabled]="isDisabled"></app-trabalhador-dependente>
          </ng-template>
        </li>
        <li [ngbNavItem]="4" [disabled]="isDisabled">
          <a ngbNavLink>Contratos</a>
          <ng-template ngbNavContent>
            <app-trabalhador-contrato [formTrabalhador]="trabalhadorForm" [isDisabled]="isDisabled">
            </app-trabalhador-contrato>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="px-3 py-3 bg-white border border-top-0 rounded-bottom"></div>
    </div>

    <div class="container-fluid text-right pt-3">
      <input type="button" [routerLink]="['/trabalhador']" value="Cancelar" class="btn btn-warning mr-3" />
      <input type="submit" value="Salvar" class="btn btn-success" [disabled]="trabalhadorForm.invalid" />
    </div>
  </form>
</section>