<form *ngIf="formTrabalhador.get('dependentes')" [formGroup]="formTrabalhador" ng-model-options="{timezone: 'UTC'}">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" ></ngx-loading>

  <!-- Actions -->
  <div class="container-fluid rounded py-2" formGroupName="dependentes">

    <!-- ADICIONA NOVO DEPENDENTE -->
    <div class="row" *ngIf="!editing">
      <div class="col text-right">
        <button class="btn btn-secondary btn-sm" (click)="newDependente()">
          <i class="fa fa-plus"></i> Novo Dependente
        </button>
      </div>
    </div>

    <!-- LISTA DE DEPENDENTES -->
    <div class="row mt-4" *ngIf="!editing" >
      <div class="col">
        <table class="table table-sm table-striped">
          <thead>
            <tr class="">
              <th>Id</th>
              <th>Nome</th>
              <th>CPF</th>
              <th>Tipo</th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dependente of getContent(); index as i;">
              <td>{{ dependente.id }}</td>
              <td>{{ dependente.nomeDependente }}</td>
              <td>{{ dependente.cpf }}</td>
              <td>{{ dependente.tipoDependente }}</td>
              <td class="text-center">
                <button class="btn btn-default btn-sm" (click)="editEndereco(dependente)">
                  <i class="fas fa-edit"></i>
                  Editar
                </button>
                <!-- <button class="btn btn-default btn-sm" (click)="removeEndereco(dependente?.id)">
                  <i class="fas fa-trash"></i>
                  Excluir
                </button> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- FIM DA LISTA DE DEPENDENTES -->

    <!-- FORMULÁRIO DE INCLUSÃO DE DEPENDENTES -->
    <div *ngIf="editing">
      <div class="row">
        <input formControlName="id" type="hidden" class="form-control" id="id" />
        <div class="col-6">
          <label for="nomeDependente" class="col-form-label">Nome</label>
          <input formControlName="nomeDependente" type="text" class="form-control" id="nomeDependente"
            placeholder="Nome do dependente" />
        </div>
        <div class="col-3">
          <label for="dataNascDependente" class="col-form-label">Data de Nascimento</label>
          <input formControlName="dataNascDependente" type="date" class="form-control" id="dataNascDependente" ng-model-options="{timezone: 'UTC'}" />
        </div>
        <div class="col-3">
          <label for="cpf" class="col-form-label">CPF</label>
          <input formControlName="cpf" type="text" class="form-control" id="cpf" placeholder="000.000.000-00"
            [mask]="'000.000.000-00'" />
        </div>
      </div>
      <div class="row align-items-end">
        <div class="col-6">
          <label for="tipoDependente" class="col-form-label">Tipo de Dependente</label>
          <select formControlName="tipoDependente" class="form-control" id="tipoDependente">
            <option *ngFor="let tipo of data.tipoDependente " [value]="tipo.value">
              {{ tipo.description }}
            </option>
          </select>
        </div>
        <div class="col-3 pb-2">
          <div class="form-check ml-1">
            <input formControlName="deducaoIRRF" type="checkbox" class="form-check-input" id="deducaoIRRF">
            <label class="form-check-label" for="deducaoIRRF">Dedução IRRF?</label>
          </div>
        </div>
        <div class="col-3 pb-2">
          <div class="form-check ml-1">
            <input formControlName="salarioFamilia" type="checkbox" class="form-check-input" id="salarioFamilia">
            <label class="form-check-label" for="salarioFamilia">Salário Família?</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2" *ngIf="editing">
    <div class="col text-right">
      <button class="btn btn-sm btn-info" (click)="addDependente()">
        <i class="fa fa-check"></i> Associar Dependente
      </button>
      <button class="btn btn-danger btn-sm ml-1" (click)="editing = false">
        <i class="fa fa-ban"></i> Cancelar
      </button>
    </div>
  </div>
</form>