<div id="pagination-bar-js" *ngIf="page && (!page.empty)">
  <div class="d-flex justify-content-center">
    <ul class="pagination form-group">
      <li class="page-item" [ngClass]="page.first ? 'disabled cursor-not-allowed' : ''">
        <button type="button" (click)="changePageNumber(1)" class="page-link btn btn-flat" [ngClass]="(page.first) ? 'disabled cursor-not-allowed' : ''">
          <i class="text-success fas fa-fast-backward"></i>
        </button>
      </li>
      <li class="page-item" [ngClass]="page.first ? 'disabled cursor-not-allowed' : ''">
        <button type="button" (click)="changePageNumber(page.number)" class="page-link btn btn-flat" [ngClass]="(page.first) ? 'disabled cursor-not-allowed' : ''">
          <i class="text-success fas fa-caret-left"></i>
        </button>
      </li>
      <ng-template [ngIf]="page.totalPages < 5">
        <ng-template [ngIf]="page.totalPages >= 1">
          <li class="page-item"  [ngClass]="(page.number == 0) ? 'active' : ''">
            <button type="button" (click)="changePageNumber(1)" class="page-link btn btn-flat">1</button>
          </li>
        </ng-template>
        <ng-template [ngIf]="page.totalPages >= 2">
          <li class="page-item"  [ngClass]="(page.number == 1) ? 'active' : ''">
            <button type="button" (click)="changePageNumber(2)" class="page-link btn btn-flat">2</button>
          </li>
        </ng-template>
        <ng-template [ngIf]="page.totalPages >= 3">
          <li class="page-item"  [ngClass]="(page.number == 2) ? 'active' : ''">
            <button type="button" (click)="changePageNumber(3)" class="page-link btn btn-flat">3</button>
          </li>
        </ng-template>
        <ng-template [ngIf]="page.totalPages >= 4">
          <li class="page-item"  [ngClass]="(page.number == 3) ? 'active' : ''">
            <button type="button" (click)="changePageNumber(4)" class="page-link btn btn-flat">4</button>
          </li>
        </ng-template>
      </ng-template>
      <ng-template [ngIf]="(page.totalPages >= 5)">
        <li class="page-item" *ngIf="!(page.number <= (page.totalPages-2))">
          <button type="button" (click)="changePageNumber(page.number-3)" class="page-link btn btn-flat">{{ page.number-3 }}</button>
        </li>
        <li class="page-item" *ngIf="!(page.number <= (page.totalPages-3))">
          <button type="button" (click)="changePageNumber(page.number-2)" class="page-link btn btn-flat">{{ page.number-2 }}</button>
        </li>
        <li class="page-item" *ngIf="page.number >= 2">
          <button type="button" (click)="changePageNumber(page.number-1)" class="page-link btn btn-flat">{{ page.number-1 }}</button>
        </li>
        <li class="page-item" *ngIf="page.number >= 1">
          <button type="button" (click)="changePageNumber(page.number)" class="page-link btn btn-flat">{{ page.number }}</button>
        </li>
        <li class="page-item active">
          <button type="button" (click)="changePageNumber(page.number+1)" class="page-link btn btn-flat">{{ page.number+1 }}</button>
        </li>
        <li class="page-item" *ngIf="page.number <= (page.totalPages-2)">
          <button type="button" (click)="changePageNumber(page.number+2)" class="page-link btn btn-flat">{{ page.number+2 }}</button>
        </li>
        <li class="page-item" *ngIf="page.number <= (page.totalPages-3)">
          <button type="button" (click)="changePageNumber(page.number+3)" class="page-link btn btn-flat">{{ page.number+3 }}</button>
        </li>
        <li class="page-item" *ngIf="!(page.number >= 2)">
          <button type="button" (click)="changePageNumber(page.number+4)" class="page-link btn btn-flat">{{ page.number+4 }}</button>
        </li>
        <li class="page-item" *ngIf="!(page.number >= 1)">
          <button type="button" (click)="changePageNumber(page.number+5)" class="page-link btn btn-flat">{{ page.number+5 }}</button>
        </li>
      </ng-template>

      <li class="page-item" [ngClass]="page.last ? 'disabled cursor-not-allowed' : ''">
        <button type="button" (click)="changePageNumber(page.number+2)" class="page-link btn btn-flat" [ngClass]="page.last ? 'disabled cursor-not-allowed' : ''">
          <i class="text-success fas fa-caret-right"></i>
        </button>
      </li>
      <li class="page-item" [ngClass]="page.last ? 'disabled cursor-not-allowed' : ''">
        <button type="button" (click)="changePageNumber(page.totalPages)" class="page-link btn btn-flat" [ngClass]="page.last ? 'disabled cursor-not-allowed' : ''">
          <i class="text-success fas fa-fast-forward"></i>
        </button>
      </li>
    </ul>

    <div class="form-group ml-2">
      <select id="select-pagination-size" type="number"class="form-control" style="width: 100%;"
              [ngModel]="page.size"
              (change)="changeSize($any($event.target).value)">
        <option *ngFor="let c of sizes" [value]="c">{{c}}</option>
      </select>
    </div>
  </div>
</div>
