import { Trabalhador } from "../trabalhador.model";
import { ItemContrato } from "./itemContrato.model";

export class Contrato {
  id?: number;
  codigoDecisaoJudicial?: number;
  codigoOrgao: number;
  codigoUnidadeAdm: number;
  observacao?: string;
  dataInicioContrato: Date;
  dataFimContrato: Date;
  indiEstagio: string;
  numeroCnpjEstagio: number;
  indiNaturezaEstagio: string;
  indiNivelInstrucaoEstagio?: number;
  numeroApoliceSeguro: number;
  numeroCpfSupervisorEstagio: number;
  infoEstagioAreaAtuacao?: string;
  codigoCboTrabalhador: number;
  trabalhador: Trabalhador;
  codigoCategoriaTabalhador?: number;
  codigoBancoTrabalhador: number;
  codigoAgenciaTrabalhador: number;
  nomeCargo: string;
  statusContrato: string;
  itemsContrato: ItemContrato[];
}
