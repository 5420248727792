import { Component, OnInit, LOCALE_ID, Input, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { debounceTime, Observable, OperatorFunction, map } from "rxjs";
import { Pais } from "src/app/modules/core/models/tsv/pais.model";
import { Trabalhador } from "src/app/modules/core/models/tsv/trabalhador.model";
import { PaisService } from "src/app/modules/core/services/pais/pais.service";
import { TrabalhadorService } from "src/app/modules/core/services/trabalhador/trabalhador.service";
import { ActivatedRoute, Router } from "@angular/router";
import { formatDate } from "@angular/common";
import { condicaoIngressoImigrante, estadoCivil, grausInstrucao, racasCores, sexos, tempoDeResidencia } from "../../../../assets/data";

@Component({
  selector: "app-trabalhador-identification",
  templateUrl: "./trabalhador-identification.component.html",
  styleUrls: ["./trabalhador-identification.component.scss"],
})
export class TrabalhadorIdentificationComponent implements OnInit {
  @Input() trabalhador: Trabalhador;
  @Input() formTrabalhador: FormGroup;
  paises: Array<Pais> = [];
  @Input() dtNascimento: Date;

  public data = {
    condicaoIngressoImigrante,
    estadoCivil,
    grausInstrucao,
    racasCores,
    sexos,
    tempoDeResidencia,
  };

  public loading: boolean = false;
  public loaded: boolean = false;
  public pg: string = "trabalhador";
  public paisNacionalidade: Pais;
  public paisNascimento: Pais;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private paisService: PaisService,
    private service: TrabalhadorService,
    private activatedRoute: ActivatedRoute,
    private _router: Router,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  setTrabalhador() {
    let id: number = parseInt(String(this.activatedRoute.snapshot.paramMap.get("id")));

    if (id) {
      this.loaded = false;
      this.loading = true;
      this.service.findOne(id, this.pg).subscribe({
        next: (response) => {
          this.trabalhador.id = response.id;
          this.trabalhador.cpf = response.cpf;
          this.trabalhador.nome = response.nome;
          this.trabalhador.nomeSocial = response.nomeSocial;
          this.trabalhador.dataNascimento = new Date(response.dataNascimento);
          this.trabalhador.sexo = response.sexo;
          this.trabalhador.racaCor = response.racaCor;
          this.trabalhador.estadoCivil = response.estadoCivil;
          this.trabalhador.tpGrauInstr = response.tpGrauInstr;
          this.trabalhador.paisNascimento = response?.paisNascimento;
          this.trabalhador.paisNacionalidade = response?.paisNacionalidade;
          this.trabalhador.resideExterior = response?.resideExterior;
          this.trabalhador.imigrante = response?.imigrante;
          this.trabalhador.possuiDeficiencia = response?.possuiDeficiencia;
          this.trabalhador.possuiDependentes = response?.possuiDependentes;
          this.trabalhador.deficienteFisico = response?.deficienteFisico;
          this.trabalhador.deficienteVisual = response?.deficienteVisual;
          this.trabalhador.deficienteAuditivo = response?.deficienteAuditivo;
          this.trabalhador.deficienteMental = response?.deficienteMental;
          this.trabalhador.deficienteIntelectual = response?.deficienteIntelectual;
          this.trabalhador.tempoResidencia = response?.tempoResidencia;
          this.trabalhador.condicaoIngresso = response?.condicaoIngresso;
        },
        error: (error) => {
          this.toastr.error(error.error.message);
          this.loading = false;
        },
        complete: () => {
          this.createForm();
          this.loading = false;
          this.loaded = true;
        },
      });
    } else {
      this.newForm();
      this.loading = false;
      this.loaded = true;
    }
  }

  createForm(): void {
    (this.formTrabalhador as FormGroup).addControl(
      "trabalhador",
      this.formBuilder.group({
        cpf: [this.setCpf(String(this.trabalhador.cpf))],
        nome: [this.trabalhador.nome],
        nomeSocial: [this.trabalhador.nomeSocial],
        dataNascimento: [this.transformDate(this.trabalhador.dataNascimento)],
        sexo: [this.trabalhador.sexo],
        racaCor: [this.trabalhador.racaCor],
        estadoCivil: [this.trabalhador.estadoCivil],
        grauInstrucao: [this.trabalhador.tpGrauInstr],
        paisNascimento: [this.setPais(parseFloat(String(this.trabalhador.paisNascimento)))],
        paisNacionalidade: [this.setPais(parseFloat(String(this.trabalhador.paisNacionalidade)))],
        resideExterior: [this.parseSimOrNaoForBool(String(this.trabalhador.resideExterior))],
        imigrante: [this.parseSimOrNaoForBool(String(this.trabalhador.imigrante))],
        possuiDeficiencia: [this.parseSimOrNaoForBool(String(this.trabalhador.possuiDeficiencia))],
        possuiDependentes: [this.parseSimOrNaoForBool(String(this.trabalhador.possuiDependentes))],
        deficienteFisico: [this.parseSimOrNaoForBool(String(this.trabalhador.deficienteFisico))],
        deficienteAuditivo: [this.parseSimOrNaoForBool(String(this.trabalhador.deficienteAuditivo))],
        deficienteMental: [this.parseSimOrNaoForBool(String(this.trabalhador.deficienteMental))],
        deficienteVisual: [this.parseSimOrNaoForBool(String(this.trabalhador.deficienteVisual))],
        deficienteIntelectual: [this.parseSimOrNaoForBool(String(this.trabalhador.deficienteIntelectual))],
        tempoResidencia: [this.trabalhador.tempoResidencia],
        condicaoIngresso: [this.trabalhador.condicaoIngresso],
      })
    );
  }

  newForm(): void {
    (this.formTrabalhador as FormGroup).addControl(
      "trabalhador",
      this.formBuilder.group({
        cpf: "",
        nome: "",
        nomeSocial: "",
        dataNascimento: [this.parseDate("")],
        sexo: "",
        racaCor: "",
        estadoCivil: "",
        resideExterior: "",
        grauInstrucao: "",
        paisNascimento: [""],
        paisNacionalidade: [""],
        residenteExterior: false,
        imigrante: false,
        possuiDeficiencia: false,
        possuiDependentes: false,
        deficienteFisico: false,
        deficienteAuditivo: false,
        deficienteMental: false,
        deficienteVisual: false,
        deficienteIntelectual: false,
        tempoResidencia: 1,
        condicaoIngresso: 7,
      })
    );
    this.LimparAutoComplete();
  }

  private LimparAutoComplete() {
    this.formTrabalhador?.get("trabalhador")?.get("paisNascimento")?.reset();
    this.formTrabalhador?.get("trabalhador")?.get("paisNacionalidade")?.reset();
  }

  async ngOnInit(): Promise<void> {
    this.trabalhador = new Trabalhador();
    await this.getPaises();
  }

  searchPais: OperatorFunction<string, readonly { id: number; nome: string }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map((term) => (term === "" ? [] : this.paises.filter((pais) => pais.nome.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))
    );

  formatterPais = (pais: { id: number; nome: string }) => {
    return `${pais.nome}`;
  };

  setCpf(cpf: string) {
    cpf = String(cpf);

    if (cpf.length < 11) {
      for (let i = cpf.length; i < 11; i++) {
        cpf = `0${cpf}`;
      }
    }

    return cpf;
  }

  async getPaises() {
    this.loading = true;
    await this.paisService.findAll().subscribe({
      next: (response: any): any => {
        this.paises = response;
        this.setTrabalhador();
      },
      error: (error) => {
        this.toastr.error(error.error.message);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  setPais(id: number = 1): Pais {
    if (isNaN(id)) {
      id = 1;
    }
    if (this.paises.length > 0) {
      let pais = this.paises.find((pais) => pais.id == id);
      return pais!;
    }
    return new Pais();
  }

  listnerForm(): void {
    this.formTrabalhador.controls.trabalhador.get("paisNascimento")?.valueChanges.subscribe((value) => {
      this.trabalhador.paisNascimento = value.id;
    });
    this.formTrabalhador.controls.trabalhador.get("paisNacionalidade")?.valueChanges.subscribe((value) => {
      this.trabalhador.paisNacionalidade = value.id;
    });
  }

  transformDate(date: Date) {
    return formatDate(date, "yyyy-MM-dd", this.locale, 'GMT+8');
  }

  parseSimOrNaoForBool(SimOrNao: string) {
    if (SimOrNao == "N") {
      return false;
    } else if (SimOrNao == "S") {
      return true;
    } else {
      return false;
    }
  }

  parseBoolForSimOrNao(bool: Boolean) {
    if (bool == false) {
      return "N";
    } else if (bool == true) {
      return "S";
    } else {
      return "N";
    }
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return new Date();
  }
}
