import {Component, OnInit} from '@angular/core';
import {DateTime} from 'luxon';
import {OAuthService} from 'angular-oauth2-oidc';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import {SessionStorageService} from '../../../core/services/session-storage/session-storage.service';
import {AuthService} from '../../../core/auth/auth.service';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

    // Set this to true to revoke access and refresh tokens on logout
    public readonly revokeTokenOnLogout = true;
    public user: any;

    constructor(private oauthService: OAuthService,
                private http: HttpClient,
                private sessionStorageService: SessionStorageService,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        const token = this.oauthService.getAccessToken();
        const claims = this.oauthService.getIdentityClaims();
        if (claims) {
            this.user = {name: (<any> claims)['sub'], email: (<any> claims)['email'], given_name: (<any> claims)['given_name']};
        } else {
            this.user = {name: '', email: '', given_name: ''};
        }
    }

    get logoutUrl() {
        return (<any> this.oauthService.logoutUrl);
    }

    get idToken() {
        return this.oauthService.getIdToken();
    }

    get urlRedirect() {
        return (<any> this.oauthService.redirectUri);
    }

    emailCorporativo() {
        return (<any> this.authService).getEmailCorporativo();
    }

    onSubmit(request: any) {
        this.sessionStorageService.clear();
        document.forms[0].submit();
    }

    formatDate({date}: { date: any }) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
